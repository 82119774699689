import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../Components/Button";

export default function ShowCustomer() {
  const { customerId } = useParams();
  const iframeRef = useRef();

  const navigate = useNavigate();

  const [dimensions, setDimensions] = useState({ width: 1280, height: 720 });

  function handleMaximizeButton() {
    iframeRef.current.requestFullscreen();
  }

  useEffect(() => {
    window.addEventListener(
      "message", // "message" means, that we got a message from the iframe
      (event) => {
        switch (event.data?.func) {
          case "navigateToJob":
            navigate("../showjob/" + event.data?.jobId);
            break;
          case "navigateToCustomer":
            navigate("../showcustomer/" + event.data?.customerId);
            break;
          case "navigateToCustomerId":
            navigate("../showcustomer/" + event.data?.customerId);
            break;
          case "handleIsLoaded":
            // do nothing
            break;

          default:
            break;
        }
      },
      false
    );
  }, [navigate]);

  return (
    <>
      <div className="relative">
        <h1>Show World</h1>
      </div>

      <p>One world loaded here</p>

      <h2>change dimensions</h2>
      <div className="space-x-2 w-full">
        <Button onClick={handleMaximizeButton}>fullscreen</Button>
        <Button onClick={() => setDimensions({ width: 256, height: 144 })}>
          144p
        </Button>
        <Button onClick={() => setDimensions({ width: 426, height: 240 })}>
          240p
        </Button>
        <Button onClick={() => setDimensions({ width: 640, height: 360 })}>
          360p
        </Button>
        <Button onClick={() => setDimensions({ width: 853, height: 480 })}>
          480p
        </Button>
        <Button onClick={() => setDimensions({ width: 1280, height: 720 })}>
          720p
        </Button>
        <Button onClick={() => setDimensions({ width: 1920, height: 1080 })}>
          1080p
        </Button>
        <Button onClick={() => setDimensions({ width: 2560, height: 1440 })}>
          1440p
        </Button>
      </div>

      <div className="border-2 w-fit h-fit">
        <iframe
          ref={iframeRef}
          name="career-captain"
          title={"career-captain"}
          src={`https://stage.frame.career-captain.de/web/${customerId}`}
          height={`${dimensions.height}px`}
          width={`${dimensions.width}px`}
          allowFullScreen={true}
        />
      </div>
    </>
  );
}
