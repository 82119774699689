export const msalConfig = {
  auth: {
    clientId: "ca7ad9ad-f1a7-4fb9-a361-a90e0235d984",
    authority:
      "https://login.microsoftonline.com/0db703b8-cf41-4eb9-8251-9346d94b32ca", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: window.location.protocol + "//" + window.location.host,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [],
};
