import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../Components/Button";
import LoadingAnimation from "../Components/LoadingAnimation";
import { Config } from "../Config";
import { UserContext } from "../Layout/Main";

export default function ListPreviewModules() {
  const [loading, setLoading] = useState(false);
  const [modules, setModules] = useState([]);

  const user = useContext(UserContext);

  const { filter, filterId } = useParams();

  useEffect(() => {
    getModules();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getModules() {
    setLoading(true);

    const response = await fetch(Config.api.previewmodule.list);
    let data = await response.json();

    if (filter) {
      data = data.filter((item) => item[filter] === filterId);
    }

    setModules(data);
    setLoading(false);
  }

  return (
    <>
      <h1>Preview-Modules</h1>
      <p>Modules work in progress are listed here.</p>

      <h2>
        {filter ? (
          <>
            [{filter}: {filterId}]
          </>
        ) : null}
      </h2>

      <LoadingAnimation loading={loading} />

      <table className="table-auto w-full border-2 border-slate-300 text-left">
        <thead className="border-2 border-slate-300">
          <tr>
            <th className="p-2">Preview Module name</th>
            <th className="p-2">Responsible person</th>
            <th className="p-2">Open</th>
            <th className="p-2">Edit</th>
          </tr>
        </thead>
        <tbody>
          {modules.map((module) => (
            <tr key={module.previewmoduleId}>
              <td className="p-2">{module.name}</td>
              <td className="p-2">{module.responsiblePerson}</td>

              <td className="p-2">
                <Button to={`../showpreviewmodule/${module.previewmoduleId}`}>
                  Open
                </Button>
              </td>

              <td className="p-2">
                <Button to={`../editpreviewmodule/${module.previewmoduleId}`}>
                  Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {user.permissionLevel >= 2 && (
        <>
          <h2>Create</h2>
          <p>If you want to create a new module, click here: </p>
          <p>
            <Button to="/createpreviewmodule">Create a preview-module</Button>
          </p>
        </>
      )}
    </>
  );
}
