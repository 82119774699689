import { forwardRef } from "react";

const InputField = forwardRef(
  ({ defaultValue, placeholder, name, small, id, type }, ref) => (
    <div
      className={
        small ? "flex flex-col gap-1 w-48" : "flex flex-col gap-1 w-full"
      }
    >
      <label htmlFor={id}>{name}</label>
      <input
        type={type ? type : "text"}
        ref={(el) => (ref.current[id] = el)}
        id={id}
        defaultValue={defaultValue}
        placeholder={placeholder}
        className="border-2 p-1 w-full"
      />
    </div>
  )
);

const InputCheckbox = forwardRef(({ defaultValue, name, id }, ref) => (
  <div className="flex flex-col gap-1 w-full">
    <label htmlFor={id}>{name}</label>
    <input
      type="checkbox"
      ref={(el) => (ref.current[id] = el)}
      id={id}
      defaultChecked={defaultValue}
      className="border-2 p-1 w-6 h-6"
    />
  </div>
));

const InputArea = forwardRef(
  ({ defaultValue, name, placeholder, id, size, maxLength }, ref) => (
    <div className="flex flex-col gap-1 w-full">
      <label htmlFor={id}>{name}</label>
      <textarea
        maxLength={maxLength}
        rows={size ? size : 20}
        ref={(el) => (ref.current[id] = el)}
        id={id}
        defaultValue={defaultValue}
        className="border-2 p-1"
        placeholder={placeholder}
      />
    </div>
  )
);

export const Input = ({
  label,
  id,
  setState,
  state,
  placeholder,
  type = "text",
  step,
}) => (
  <div>
    <label htmlFor={id}>{label}</label>
    <input
      className="border-2 p-1 w-full"
      id={id}
      step={step}
      type={type}
      placeholder={placeholder}
      value={
        state && state[id]
          ? type === "date"
            ? state[id]?.substring(0, 10)
            : state[id]
          : ""
      }
      onChange={(e) => setState((prev) => ({ ...prev, [id]: e.target.value }))}
    />
  </div>
);

export const Textarea = ({
  label,
  id,
  setState,
  state,
  placeholder,
  maxLength,
}) => (
  <div>
    <label htmlFor={id}>{label}</label>
    <textarea
      maxLength={maxLength}
      className="border-2 p-1 w-full"
      id={id}
      rows={6}
      placeholder={placeholder}
      value={(state && state[id]) || ""}
      onChange={(e) => setState((prev) => ({ ...prev, [id]: e.target.value }))}
    />
  </div>
);

export const Checkbox = ({ label, id, setState, state }) => (
  <div className="flex flex-col">
    <label htmlFor={id}>{label}</label>
    <input
      className="border-2 p-1 w-full"
      id={id}
      type="checkbox"
      checked={(state && state[id]) || false}
      onChange={(e) =>
        setState((prev) => ({ ...prev, [id]: e.target.checked }))
      }
    />
  </div>
);

export { InputField, InputCheckbox, InputArea };
