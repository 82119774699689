import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../Components/Button";
import LoadingAnimation from "../Components/LoadingAnimation";
import Modal from "../Components/Modal";
import { Config, jwtExpiredStatus } from "../Config";
import checkJwtExpiration from "../Functions/checkJwtExpiration";

export default function EditPermission(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [permissionNames, setPermissionNames] = useState([]);
  const [status, setStatus] = useState({ code: 0, message: "", body: "" });
  const [user, setUser] = useState([]);
  // const [type, setType] = useState("Customer");
  const type = "Customer";
  let { userId } = useParams();

  const objectRef = useRef();

  async function getUser() {
    // get user data
    const responseUser = await fetch(
      Config.api.permission.listusers + "?query=user_id:" + userId
    );
    setUser(await responseUser.json());

    // get permission of user
    const responsePermissions = await fetch(Config.api.permission.list);
    const data = await responsePermissions.json();
    const permissions = data.filter((el) => el.userId === userId);
    setPermissions(permissions);

    // get names of persmissions
    let permissionList = "";
    permissions.forEach((el) => {
      permissionList += el.objectId;
    });
    const responseNames = await fetch(
      Config.api.permission.getnames.replace("{id}", permissionList)
    );
    setPermissionNames(await responseNames.json());

    setLoading(false);
  }

  useEffect(() => {
    getUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getData();
  }, [type]); // eslint-disable-line react-hooks/exhaustive-deps

  async function getData() {
    setLoading(true);
    let url;
    switch (type) {
      case "Customer":
        url = Config.api.customer.list;
        break;
      case "Job":
        url = Config.api.job.list;
        break;
      case "Person":
        url = Config.api.person.list;
        break;
      case "Information":
        url = Config.api.information.list;
        break;
      default:
        break;
    }
    const response = await fetch(url);
    let data = await response.json();

    if (type === "Person" || type === "Information" || type === "Job")
      data.sort((a, b) =>
        a.customerDisplayName.localeCompare(b.customerDisplayName)
      );

    setData(data);

    setLoading(false);
  }

  async function createPermission() {
    setLoading(true);

    if (!checkJwtExpiration(props.token)) {
      setStatus(jwtExpiredStatus);
      setLoading(false);
      return;
    }

    const response = await fetch(Config.api.permission.create, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${props.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: userId,
        type: type,
        objectId: objectRef.current.value,
      }),
    });

    setLoading(false);
    let responsetext = await response.text();

    setStatus({
      code: response.status,
      text: response.statusText,
      body: responsetext,
    });
    getUser();
  }

  async function deletePermission(userId, type, objectId) {
    setLoading(true);

    const response = await fetch(Config.api.permission.delete, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${props.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: userId,
        type: type,
        objectId: objectId,
      }),
    });

    if (response.status === 200) {
      getUser();
    } else {
      setStatus({
        code: response.status,
        text: response.statusText,
        body: await response.text(),
      });
    }
  }

  return (
    <>
      <h1>Edit user permissions</h1>
      <p>Here you can create or remove permissions</p>

      <LoadingAnimation loading={loading} />
      {
        <Modal
          open={status.code !== 0 && !loading}
          title={status.code + ": " + status.text}
        >
          {status.body}
        </Modal>
      }

      <h2>Information</h2>
      <p>Selected user:</p>
      <p className="bg-cclightgreen w-fit p-1 px-2 border-2 border-ccdarkgreen">
        {userId} - {user[0]?.email} - {user[0]?.nickname}
      </p>

      <h2>Permissions of the selected user</h2>
      <table className="table-auto w-full border-2 border-slate-300 text-left">
        <thead className="border-2 border-slate-300">
          <tr>
            <th className="p-2">Type</th>
            <th className="p-2">Object ID</th>
            <th className="p-2">Customer</th>
            <th className="p-2">Remove</th>
          </tr>
        </thead>
        <tbody>
          {permissions.map((perm) => (
            <tr key={perm.objectId}>
              <td className="p-2">{perm.type}</td>
              <td className="p-2">
                {permissionNames.find(
                  ({ recourceId }) => recourceId === perm.objectId
                )?.displayName || perm.objectId}
              </td>

              <td className="p-2">
                <Button
                  to={
                    "/" +
                    perm.type.toLowerCase() +
                    "/" +
                    perm.type.toLowerCase() +
                    "Id=" +
                    perm.objectId
                  }
                >
                  Show {perm.type.toLowerCase()}
                </Button>
              </td>

              <td className="p-2">
                <Button
                  onClick={() =>
                    deletePermission(perm.userId, perm.type, perm.objectId)
                  }
                >
                  Remove
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2>Add a permission to the selected user</h2>
      {/* <p>Select a type:</p>
      <select
        className="border-2 p-1 w-full"
        onChange={(e) => setType(e.target.value)}
      >
        <option value="Customer">Customer</option>
        <option value="Job">Job</option>
        <option value="Person">Person</option>
        <option value="Information">Information</option>
      </select> */}

      <p>Select the object:</p>
      <select className="border-2 p-1 w-full" ref={objectRef}>
        {data.map((el, i) =>
          type === "Customer" ? (
            <option key={i} value={el.customerId}>
              {el.displayName}
            </option>
          ) : type === "Job" ? (
            <option key={i} value={el.jobId}>
              {el.customerDisplayName} - {el.displayName}
            </option>
          ) : type === "Information" ? (
            <option key={i} value={el.informationId}>
              {el.customerDisplayName} - {el.displayName}
            </option>
          ) : (
            <option key={i} value={el.personId}>
              {el.customerDisplayName} - "{el.firstName} {el.familyName}" -
              {el.email}
            </option>
          )
        )}
      </select>
      <div>
        <Button onClick={createPermission}>Create</Button>
      </div>
    </>
  );
}
