import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../Components/Button";
import { InputField } from "../Components/Inputs";
import LoadingAnimation from "../Components/LoadingAnimation";
import Modal from "../Components/Modal";
import { Config, jwtExpiredStatus } from "../Config";
import { UserContext } from "../Layout/Main";
import checkJwtExpiration from "../Functions/checkJwtExpiration";

export default function EditInformation(props) {
  const user = useContext(UserContext);

  const { informationId } = useParams();

  const refArray = useRef([]);

  const [showDelete, setShowDelete] = useState(false);

  const [information, setInformation] = useState([]);

  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState({ code: 0, message: "", body: "" });

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getData() {
    setLoading(true);

    const response = await fetch(
      Config.api.information.get.replace("{id}", informationId)
    );
    const data = await response.json();
    setInformation(data);

    setLoading(false);
  }

  async function changeInformation() {
    setLoading(true);

    const bodyjson = JSON.stringify({
      displayName: refArray.current.displayName.value,
      link: refArray.current.link.value,
    });

    if (!checkJwtExpiration(props.token)) {
      setStatus(jwtExpiredStatus);
      setLoading(false);
      return;
    }

    const response = await fetch(
      Config.api.information.changevalues.replace("{id}", informationId),
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${props.token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: bodyjson,
      }
    );

    setLoading(false);
    let responsetext = await response.text();

    setStatus({
      code: response.status,
      text: response.statusText,
      body: responsetext,
    });
  }

  async function deleteInformation() {
    setLoading(true);

    if (!checkJwtExpiration(props.token)) {
      setStatus(jwtExpiredStatus);
      setLoading(false);
      return;
    }

    const response = await fetch(
      Config.api.information.delete.replace("{id}", informationId),
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${props.token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    setLoading(false);
    let responsetext = await response.text();

    setStatus({
      code: response.status,
      text: response.statusText,
      body: responsetext,
    });
  }

  return (
    <>
      <h1>Edit Information</h1>
      <p>Here are infos of a information.</p>
      <LoadingAnimation loading={loading} />
      {
        <Modal
          open={status.code !== 0 && !loading}
          title={status.code + ": " + status.text}
        >
          {status.body}
        </Modal>
      }
      <h2>Information [{informationId}]</h2>
      <InputField
        id="displayName"
        ref={refArray}
        defaultValue={information.displayName}
        name="Display Name"
      />
      <InputField
        id="link"
        ref={refArray}
        defaultValue={information.link}
        name="Link"
      />
      <div>
        <Button onClick={() => changeInformation()}>Change Values</Button>
      </div>

      {user.permissionLevel >= 3 && (
        <>
          <h2>Delete information</h2>
          <input
            onChange={(e) => {
              e.target.checked ? setShowDelete(true) : setShowDelete(false);
            }}
            type="checkbox"
            defaultChecked={false}
          />{" "}
          I'm sure I want to delete this information
          <div className={showDelete ? "visible" : "invisible"}>
            <Button onClick={() => deleteInformation()}>
              Delete information
            </Button>
          </div>
        </>
      )}
    </>
  );
}
