import { useEffect, useState } from "react";
import Button from "../Components/Button";
import LoadingAnimation from "../Components/LoadingAnimation";
import Modal from "../Components/Modal";
import { Config, jwtExpiredStatus } from "../Config";
import { useParams } from "react-router-dom";
import checkJwtExpiration from "../Functions/checkJwtExpiration";

export default function CreateInformation(props) {
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState();
  const [status, setStatus] = useState({ code: 0, message: "", body: "" });

  const { customerId } = useParams();

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getData() {
    setLoading(true);

    const responseCustomer = await fetch(
      Config.api.customer.get.replace("{id}", customerId)
    );
    setCustomer(await responseCustomer.json());

    setLoading(false);
  }

  async function createInformation() {
    setLoading(true);

    const bodyjson = JSON.stringify({
      customerId: customerId,
    });

    if (!checkJwtExpiration(props.token)) {
      setStatus(jwtExpiredStatus);
      setLoading(false);
      return;
    }

    const response = await fetch(Config.api.information.create, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${props.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: bodyjson,
    });

    setLoading(false);
    let responsetext = await response.text();

    setStatus({
      code: response.status,
      text: response.statusText,
      body: responsetext,
    });
  }

  return (
    <>
      <h1>Create a new information</h1>
      <p>Here you can create a new information</p>

      <LoadingAnimation loading={loading} />

      <Modal
        open={status.code !== 0 && !loading}
        title={status.code + ": " + status.text}
      >
        {`Information was sucessfully created! (${status.body})`}
        <br />
        {status.code === 201 ? (
          <p>
            <Button to={"/editinformation/" + status.body.slice(1, -1)}>
              Edit information
            </Button>
          </p>
        ) : null}
      </Modal>

      <h2>Create information</h2>

      <p>Selected customer:</p>
      <p className="bg-cclightgreen w-fit p-1 px-2 border-2 border-ccdarkgreen">
        {customer?.displayName}
      </p>

      {/* <p>Select the customer:</p>
      <select
        className="border-2 p-1 w-full"
        ref={customerRef}
        name="customer"
        id="customer"
      >
        {customer.map((el) => (
          <option key={el.customerId} value={el.customerId}>
            {el.displayName}
          </option>
        ))}
      </select> */}

      <div>
        <Button onClick={createInformation}>Create</Button>
      </div>
    </>
  );
}
