import { useEffect, useRef, useState } from "react";
import Button from "../Components/Button";
import LoadingAnimation from "../Components/LoadingAnimation";
import Modal from "../Components/Modal";
import { Config, jwtExpiredStatus } from "../Config";
import checkJwtExpiration from "../Functions/checkJwtExpiration";

export default function CreateModule(props) {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ code: 0, message: "", body: "" });
  const [modules, setModules] = useState([]);

  const moduleRef = useRef();

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getData() {
    setLoading(true);

    const responseAllModules = await fetch(Config.api.module.list);
    const allModules = await responseAllModules.json();

    const responseArcweave = await fetch(Config.api.arcweave.allModules);

    const arcweaveModules = [];
    const boards = (await responseArcweave.json()).boards;

    boards["eaaa2bd4-67e2-4334-a459-009ec6a56d22"].children.forEach(
      (folderId) => {
        boards[folderId].children.forEach((id) => {
          const dir = boards[folderId].name;
          const subDir = boards[id].name;

          if (!allModules.find((e) => e.moduleId === id)) {
            arcweaveModules.push({ moduleId: id, dir: dir, subDir: subDir });
          }
        });
      }
    );

    setModules(arcweaveModules);

    setLoading(false);
  }

  async function createModule() {
    setLoading(true);

    const findModule = modules.find(
      (e) => e.moduleId === moduleRef.current.value
    );

    if (!checkJwtExpiration(props.token)) {
      setStatus(jwtExpiredStatus);
      setLoading(false);
      return;
    }

    const response = await fetch(Config.api.module.create, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${props.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(findModule),
    });

    setLoading(false);
    let responseText = await response.text();

    setStatus({
      code: response.status,
      text: response.statusText,
      body: responseText,
    });
  }

  return (
    <>
      <h1>Create a new Module</h1>
      <p>Here you can import a module from arcweave</p>

      <LoadingAnimation loading={loading} />
      {
        <Modal
          open={status.code !== 0 && !loading}
          title={status.code + ": " + status.text}
        >
          {`Module was sucessfully created! (${status.body})`}
          <br />
          {status.code === 201 ? (
            <p>
              <Button to={"/listmodules/"}>Back</Button>
            </p>
          ) : null}
        </Modal>
      }

      <h2>Create module</h2>
      <p>Select an Arcweave folder that does not yet exist as a module:</p>
      <select
        className="border-2 p-1 w-full"
        ref={moduleRef}
        name="customer"
        id="customer"
      >
        {modules.map((el) => (
          <option key={el.moduleId} value={el.moduleId}>
            {el.dir} - {el.subDir}
          </option>
        ))}
      </select>

      <div>
        <Button onClick={createModule}>Create</Button>
      </div>
    </>
  );
}
