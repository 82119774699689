import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../Components/Button";
import LoadingAnimation from "../Components/LoadingAnimation";
import Modal from "../Components/Modal";
import { Config, jwtExpiredStatus } from "../Config";
import checkJwtExpiration from "../Functions/checkJwtExpiration";

export default function ImagesModulePreview(props) {
  const { moduleId } = useParams();

  const [module, setModule] = useState([]);

  const dim = [1920, 1080];

  const [file, setFile] = useState();

  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState({ code: 0, message: "", body: "" });

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getData() {
    setLoading(true);
    const response = await fetch(
      Config.api.module.get.replace("{id}", moduleId)
    );
    const data = await response.json();
    setModule(data);
    setLoading(false);
  }

  async function uploadImage() {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file.file);
    formData.append("path", `${module.dir}_${module.subDir}.png`);
    formData.append("container", "module");

    if (!checkJwtExpiration(props.token)) {
      setStatus(jwtExpiredStatus);
      setLoading(false);
      return;
    }

    const response = await fetch(Config.api.file.image, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${props.token}`,
      },
      body: formData,
    });
    const responsetext = await response.text();
    setStatus({
      code: response.status,
      text: response.statusText,
      body: responsetext,
    });

    setLoading(false);
  }

  function handleFileChange(e) {
    if (e.target.files.length === 0) return;
    let img = new Image();
    img.src = window.URL.createObjectURL(e.target.files[0]);
    img.onload = () => {
      setFile({
        file: e.target.files[0],
        width: img.width,
        height: img.height,
      });
    };
  }

  return (
    <>
      <h1>Module Images </h1>
      <p>Here are the images of a module.</p>
      <LoadingAnimation loading={loading} />
      {
        <Modal
          open={status.code !== 0 && !loading}
          title={status.code + ": " + status.text}
        >
          {status.body}
        </Modal>
      }
      <h2>
        Module Images [{module.dir}/{module.subDir}]
      </h2>
      <p>{module.name}</p>
      {!loading && (
        <img
          className="aspect-video w-1/3 bg-[url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUBAMAAAB/pwA+AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAGUExURdjY2P///wHRpHEAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAWSURBVBjTYwADQRCgB5NuFjEwMDAAANtyBqXVH2kBAAAAAElFTkSuQmCC)]"
          src={`https://images.career-captain.de/module/${module.dir}_${
            module.subDir
          }.png?t=${Date.now()}`}
          alt="preview module"
        />
      )}
      <h2>Upload a new image</h2>
      <p>choose your file</p>
      <input
        onChange={handleFileChange}
        className="file:bg-white file:border-black file:border-2 file:p-1 file:px-2"
        accept=".png"
        type="file"
        id="file"
      />
      {!(file && file?.width === dim[0] && file?.height === dim[1]) && (
        <mark className="bg-ccred px-2">
          Please make sure the dimensions of the image are {dim[0]}x{dim[1]}
        </mark>
      )}

      <div>
        <Button
          disabled={
            !(file && file?.width === dim[0] && file?.height === dim[1])
          }
          onClick={uploadImage}
        >
          Upload Image
        </Button>
      </div>
    </>
  );
}
