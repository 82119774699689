import { LuSearch } from "react-icons/lu";

export default function SearchBar({ onChange }) {
  return (
    <div className="gap-4 flex p-2 bg-white border-2 border-slate-300">
      <LuSearch size={28} />
      <input className="w-full outline-none" onChange={onChange} />
    </div>
  );
}
