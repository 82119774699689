import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Config, jwtExpiredStatus } from "../Config";
import Modal from "../Components/Modal";
import { Input, Textarea } from "../Components/Inputs";
import Button from "../Components/Button";
import LoadingAnimation from "../Components/LoadingAnimation";
import { UserContext } from "../Layout/Main";
import checkJwtExpiration from "../Functions/checkJwtExpiration";

export default function EditCustomer(props) {
  const user = useContext(UserContext);

  const { customerId } = useParams();

  const [showDelete, setShowDelete] = useState(false);

  const [customer, setCustomer] = useState([]);
  const [allWorlds, setAllWorlds] = useState([]);

  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState({ code: 0, message: "", body: "" });

  const fikWorldSelected =
    allWorlds.find((el) => el.worldId === customer.worldId)?.customerName ===
    "FikWorld";

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getData() {
    setLoading(true);

    const response = await fetch(
      Config.api.customer.data.replace("{id}", customerId)
    );
    const data = await response.json();
    setCustomer(data);

    const responseWorlds = await fetch(Config.api.world.list);
    setAllWorlds(await responseWorlds.json());

    setLoading(false);
  }

  async function changeCustomer() {
    setLoading(true);

    if (!checkJwtExpiration(props.token)) {
      setStatus(jwtExpiredStatus);
      setLoading(false);
      return;
    }

    const response = await fetch(
      Config.api.customer.changevalues.replace("{id}", customerId),
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${props.token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(customer),
      }
    );

    setLoading(false);
    let responsetext = await response.text();

    setStatus({
      code: response.status,
      text: response.statusText,
      body: responsetext,
    });
  }

  async function deleteCustomer() {
    setLoading(true);

    if (!checkJwtExpiration(props.token)) {
      setStatus(jwtExpiredStatus);
      setLoading(false);
      return;
    }
    const response = await fetch(
      Config.api.customer.delete.replace("{id}", customerId),
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${props.token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    setLoading(false);
    let responsetext = await response.text();

    setStatus({
      code: response.status,
      text: response.statusText,
      body: responsetext,
    });
  }

  return (
    <>
      <h1>Edit Customer</h1>
      <p>
        Below you can edit / update or delete all information of a customer.
      </p>
      <LoadingAnimation loading={loading} />
      {
        <Modal
          open={status.code !== 0 && !loading}
          title={status.code + ": " + status.text}
        >
          {status.body}
        </Modal>
      }
      <h2>Customer ({customerId})</h2>

      <h2>General Informations</h2>

      <Input
        id="displayName"
        setState={setCustomer}
        state={customer}
        placeholder="Unternehmen GmbH"
        label="Display Name"
      />

      <div className="grid grid-cols-4 gap-2">
        <Input
          id="streetName"
          setState={setCustomer}
          state={customer}
          placeholder="Musterstraße"
          label="Street"
        />
        <Input
          id="houseNumber"
          setState={setCustomer}
          state={customer}
          placeholder="1"
          label="House Nr."
        />
        <Input
          id="postalCode"
          setState={setCustomer}
          state={customer}
          placeholder="99999"
          label="Postal code"
        />
        <Input
          id="cityName"
          setState={setCustomer}
          state={customer}
          placeholder="Musterort"
          label="City"
        />
      </div>

      <div className="grid grid-cols-5 gap-2">
        <Input
          id="keyword1"
          setState={setCustomer}
          state={customer}
          label="Keyword 1"
        />
        <Input
          id="keyword2"
          setState={setCustomer}
          state={customer}
          label="Keyword 2"
        />
        <Input
          id="keyword3"
          setState={setCustomer}
          state={customer}
          label="Keyword 3"
        />
        <Input
          id="keyword4"
          setState={setCustomer}
          state={customer}
          label="Keyword 4"
        />
        <Input
          id="keyword5"
          setState={setCustomer}
          state={customer}
          label="Keyword 5"
        />
      </div>

      <div className="grid grid-cols-1 gap-2">
        <Input
          id="domain"
          setState={setCustomer}
          state={customer}
          placeholder="company.de"
          label="Domain"
        />
        {/* <Input
          id="latitude"
          setState={setCustomer}
          state={customer}
          type="number"
          step={0.00001}
          placeholder="99.98765"
          label="Latitude"
        />
        <Input
          id="longitude"
          setState={setCustomer}
          state={customer}
          type="number"
          step={0.00001}
          placeholder="99.98765"
          label="Longitude"
        /> */}
      </div>

      <Textarea
        id="description"
        setState={setCustomer}
        state={customer}
        label="Description of the company"
        placeholder="We are a company that does things ..."
      />

      <Input
        id="features"
        setState={setCustomer}
        state={customer}
        label="Features of the company (comma separated)"
        placeholder="bus, new laptop"
      />

      <h2>General Settings</h2>

      {(fikWorldSelected || customer.expiryDate) && (
        <Input
          type="date"
          id="expiryDate"
          setState={setCustomer}
          state={customer}
          label="Expiry date"
        />
      )}

      <Input
        id="socialKitUrl"
        setState={setCustomer}
        state={customer}
        label="Url to Customer Social Kit"
        placeholder="https://sharepoint.com/KundenSocialKit"
      />

      <Input
        id="applyJobsLink"
        setState={setCustomer}
        state={customer}
        label="Apply Page Link to see more jobs"
        placeholder="https://some-more.jobs/link"
      />

      <Input
        id="applyUrl"
        setState={setCustomer}
        state={customer}
        label='Apply Url (custom url for the "live erleben" button) (optional)'
        placeholder="https://customer.info/apply-here"
      />

      <Input
        type="date"
        id="contractStartDate"
        setState={setCustomer}
        state={customer}
        label="Contract start date"
      />

      <Input
        id="email"
        setState={setCustomer}
        state={customer}
        label="EMail Address (for receiving emails)"
        placeholder="person@company.com"
      />

      <h2>Social Media</h2>
      <div className="grid grid-cols-6 gap-2">
        <Input
          id="linkFacebook"
          setState={setCustomer}
          state={customer}
          label="Facebook Link"
          placeholder="https://www.facebook.com/company"
        />

        <Input
          id="linkLinkedin"
          setState={setCustomer}
          state={customer}
          label="LinkedIn link"
          placeholder="https://de.linkedin.com/company"
        />

        <Input
          id="linkTiktok"
          setState={setCustomer}
          state={customer}
          label="Tiktok link"
          placeholder="https://www.tiktok.com/companyProfile"
        />

        <Input
          id="linkWebsite"
          setState={setCustomer}
          state={customer}
          label="Website link"
          placeholder="https://www.company.com"
        />

        <Input
          id="linkYoutube"
          setState={setCustomer}
          state={customer}
          label="Youtube link"
          placeholder="https://www.youtube.com/companyProfile"
        />

        <Input
          id="linkInstagram"
          setState={setCustomer}
          state={customer}
          label="Instagram link"
          placeholder="https://www.instagram.com/companyProfile"
        />
      </div>
      <div className="grid grid-cols-6 gap-2">
        <Input
          id="linkKununu"
          setState={setCustomer}
          state={customer}
          label="Kununu link"
          placeholder="https://www.kununu.com/companyProfile"
        />

        <Input
          id="linkSnapchat"
          setState={setCustomer}
          state={customer}
          label="Snapchat link"
          placeholder="https://www.snapchat.com/companyProfile"
        />

        <Input
          id="linkWhatsapp"
          setState={setCustomer}
          state={customer}
          label="WhatsApp number"
          placeholder="491234567890"
        />

        <Input
          id="linkTwitter"
          setState={setCustomer}
          state={customer}
          label="Twitter link"
          placeholder="https://www.twitter.com/companyProfile"
        />

        {/* <Input
          id="linkGithub"
          setState={setCustomer}
          state={customer}
          label="Github link"
          placeholder="https://www.github.com/companyProfile"
        /> */}

        <Input
          id="linkEmail"
          setState={setCustomer}
          state={customer}
          label="E-Mail address"
          placeholder="info@company.com"
        />
      </div>

      <h2>Game Settings</h2>

      <div>
        <label htmlFor="worldId">
          World (Unity Project which should be the Overworld)
        </label>
        <select
          value={customer.worldId}
          id="worldId"
          className="border-2 p-1 w-full"
          onChange={(e) =>
            setCustomer({ ...customer, worldId: e.target.value })
          }
        >
          <option value="00000000-0000-0000-0000-000000000000">Empty</option>
          {allWorlds.map((el) => (
            <option key={el.worldId} value={el.worldId}>
              {el.customerName + "/" + el.worldName}
            </option>
          ))}
        </select>
      </div>

      {fikWorldSelected && (
        <>
          <div>
            <label htmlFor="pin1">Job Pin 1</label>
            <select
              id="pin1"
              className="border-2 p-1 w-full"
              value={customer.pin1 || "00000000-0000-0000-0000-000000000000"}
              onChange={(e) =>
                setCustomer({ ...customer, pin1: e.target.value })
              }
            >
              <option value={"00000000-0000-0000-0000-000000000000"}>
                Empty
              </option>
              {customer.jobs?.map((el) => (
                <option key={el.worldId} value={el.worldId}>
                  {el.displayName + " - (world: " + el.worldDisplayName + ")"}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="pin2">Job Pin 2</label>
            <select
              id="pin2"
              className="border-2 p-1 w-full"
              value={customer.pin2 || "00000000-0000-0000-0000-000000000000"}
              onChange={(e) =>
                setCustomer({ ...customer, pin2: e.target.value })
              }
            >
              <option value={"00000000-0000-0000-0000-000000000000"}>
                Empty
              </option>
              {customer.jobs?.map((el) => (
                <option key={el.worldId} value={el.worldId}>
                  {el.displayName + " - (world: " + el.worldDisplayName + ")"}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="pin3">Job Pin 3</label>
            <select
              id="pin3"
              className="border-2 p-1 w-full"
              value={customer.pin3 || "00000000-0000-0000-0000-000000000000"}
              onChange={(e) =>
                setCustomer({ ...customer, pin3: e.target.value })
              }
            >
              <option value={"00000000-0000-0000-0000-000000000000"}>
                Empty
              </option>
              {customer.jobs?.map((el) => (
                <option key={el.worldId} value={el.worldId}>
                  {el.displayName + " - (world: " + el.worldDisplayName + ")"}
                </option>
              ))}
            </select>
          </div>
        </>
      )}

      <div className="py-8">
        <Button onClick={changeCustomer}>Update</Button>
      </div>

      {user.permissionLevel >= 3 && (
        <>
          <h2>Delete job</h2>
          <input
            onChange={(e) => {
              e.target.checked ? setShowDelete(true) : setShowDelete(false);
            }}
            type="checkbox"
            defaultChecked={false}
          />
          I'm sure I want to delete this customer
          <div className={showDelete ? "visible" : "invisible"}>
            <Button onClick={() => deleteCustomer()}>Delete customer</Button>
          </div>
        </>
      )}
    </>
  );
}
