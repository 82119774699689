import { useRef, useState } from "react";
import Button from "../Components/Button";
import LoadingAnimation from "../Components/LoadingAnimation";
import Modal from "../Components/Modal";
import { Config, jwtExpiredStatus } from "../Config";
import axios from "axios";
import checkJwtExpiration from "../Functions/checkJwtExpiration";

export default function CreatePreviewModule(props) {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ code: 0, message: "", body: "" });
  const [progress, setProgress] = useState(100);

  const nameRef = useRef();
  const responsiblePersonRef = useRef();
  const fileRef = useRef();

  async function createModule() {
    setLoading(true);

    const formData = new FormData();

    formData.append("name", nameRef.current.value);
    formData.append("responsiblePerson", responsiblePersonRef.current.value);

    formData.append("file1", fileRef.current.files[0]);
    formData.append("file2", fileRef.current.files[1]);
    formData.append("file3", fileRef.current.files[2]);
    formData.append("file4", fileRef.current.files[3]);

    if (!checkJwtExpiration(props.token)) {
      setStatus(jwtExpiredStatus);
      setLoading(false);
      return;
    }

    await axios
      .post(Config.api.previewmodule.create, formData, {
        onUploadProgress: (progressEvent) => {
          setProgress(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        },
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((response) => {
        setStatus({
          code: response.status,
          text: response.statusText,
          body: response.data,
        });
      })
      .catch((error) => {
        console.log(error.response.data);
        setStatus({
          code: error.response.status,
          text: error.response.statusText,
          body: error.response.data.toString(),
        });
      });

    setLoading(false);
  }

  return (
    <>
      <h1>Create a new module</h1>
      <p>Here you can create a new module</p>

      <LoadingAnimation loading={loading} />

      {loading && <h1>{progress}%</h1>}
      {
        <Modal
          open={status.code !== 0 && !loading}
          title={status.code + ": " + status.text}
        >
          {status.body}
          <br />
          <br />
          {status.code === 201 ? (
            <p>
              <Button to="/previewmodules/">Back to modules</Button>
            </p>
          ) : null}
        </Modal>
      }

      <h2>Create a new module</h2>

      <p>Please enter the module Name:</p>
      <input className="border-2 p-1" ref={nameRef} />

      <p>Please enter your name:</p>
      <input className="border-2 p-1" ref={responsiblePersonRef} />

      <p>Please upload unity files:</p>
      <input type="file" multiple className="border-2 p-1" ref={fileRef} />

      <div>
        <Button onClick={createModule}>create module</Button>
      </div>
    </>
  );
}
