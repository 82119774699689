import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../Components/Button";
import LoadingAnimation from "../Components/LoadingAnimation";
import Modal from "../Components/Modal";
import { Config } from "../Config";

export default function ImagesJob(props) {
  const { jobId } = useParams();

  const selectRef = useRef();
  const passwordRef = useRef();
  const [job, setJob] = useState([]);

  const [dim, setDim] = useState([0, 0]);

  const [file, setFile] = useState({});

  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState({ code: 0, message: "", body: "" });

  useEffect(() => {
    getJob();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getJob() {
    setLoading(true);

    const response = await fetch(Config.api.job.get.replace("{id}", jobId));
    const data = await response.json();

    setJob(data);
    setLoading(false);
  }

  async function uploadImage() {
    setLoading(true);

    const formData = new FormData();
    formData.append("file", file.file);
    formData.append("password", passwordRef.current.value);
    formData.append("container", "job");
    formData.append("id", jobId);
    formData.append("filename", selectRef.current.value);
    formData.append("filetype", "png");

    const response = await fetch("uploadfile", {
      method: "POST",
      body: formData,
    });
    const responsetext = await response.text();

    setStatus({
      code: response.status,
      text: response.statusText,
      body: responsetext,
    });

    setLoading(false);
  }

  function handleFileChange(e) {
    let img = new Image();
    img.src = window.URL.createObjectURL(e.target.files[0]);
    img.onload = () => {
      setFile({
        file: e.target.files[0],
        width: img.width,
        height: img.height,
      });
    };
  }

  return (
    <>
      <h1>Job Images </h1>
      <p>Here are the images of a job.</p>
      <LoadingAnimation loading={loading} />
      {
        <Modal
          open={status.code !== 0 && !loading}
          title={status.code + ": " + status.text}
        >
          {status.body}
        </Modal>
      }
      <h2>Job Images [{jobId}]</h2>
      <p>{job.displayName} - Icon</p>
      <img
        className="aspect-square w-1/5 bg-[url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUBAMAAAB/pwA+AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAGUExURdjY2P///wHRpHEAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAWSURBVBjTYwADQRCgB5NuFjEwMDAAANtyBqXVH2kBAAAAAElFTkSuQmCC)]"
        src={`https://careercaptainimages.blob.core.windows.net/job/${jobId}/icon.png?rand=${Math.random()}`}
        alt="icon"
      />

      <p>{job.displayName} - Banner 1</p>
      <img
        className="aspect-banner w-2/3 bg-[url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUBAMAAAB/pwA+AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAGUExURdjY2P///wHRpHEAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAWSURBVBjTYwADQRCgB5NuFjEwMDAAANtyBqXVH2kBAAAAAElFTkSuQmCC)]"
        src={`https://careercaptainimages.blob.core.windows.net/job/${jobId}/banner1.png?rand=${Math.random()}`}
        alt="banner1"
      />

      <p>{job.displayName} - Banner 2</p>
      <img
        className="aspect-banner w-2/3 bg-[url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUBAMAAAB/pwA+AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAGUExURdjY2P///wHRpHEAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAWSURBVBjTYwADQRCgB5NuFjEwMDAAANtyBqXVH2kBAAAAAElFTkSuQmCC)]"
        src={`https://careercaptainimages.blob.core.windows.net/job/${jobId}/banner2.png?rand=${Math.random()}`}
        alt="banner2"
      />

      <p>{job.displayName} - Banner 3</p>
      <img
        className="aspect-banner w-2/3 bg-[url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUBAMAAAB/pwA+AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAGUExURdjY2P///wHRpHEAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAWSURBVBjTYwADQRCgB5NuFjEwMDAAANtyBqXVH2kBAAAAAElFTkSuQmCC)]"
        src={`https://careercaptainimages.blob.core.windows.net/job/${jobId}/banner3.png?rand=${Math.random()}`}
        alt="banner3"
      />

      <h2>Upload a new image</h2>
      <p>choose your file</p>
      <input
        onChange={handleFileChange}
        className="file:bg-white file:border-black file:border-2 file:p-1 file:px-2"
        accept=".png"
        type="file"
        id="file"
      />
      {(file.width === dim[0] && file.height === dim[1]) ||
      dim[0] === 0 ? null : (
        <mark className="bg-ccred px-2">
          Please make sure the dimensions of the image are {dim[0]}x{dim[1]}
        </mark>
      )}

      <p>select a location</p>
      <select
        onChange={(e) => {
          switch (e.target.value) {
            case "icon":
              setDim([512, 512]);
              break;
            case "banner1":
              setDim([1940, 500]);
              break;
            case "banner2":
              setDim([1940, 500]);
              break;
            case "banner3":
              setDim([1940, 500]);
              break;

            default:
              setDim([0, 0]);
              break;
          }
        }}
        ref={selectRef}
        className="p-1 px-2 border-2 bg-white w-full"
        id="filename"
      >
        <option value="">select a location</option>
        <option value="icon">icon (512x512)</option>
        <option value="banner1">banner1 (1940x500)</option>
        <option value="banner2">banner2 (1940x500)</option>
        <option value="banner3">banner3 (1940x500)</option>
      </select>
      <p>staging master password</p>
      <input
        multiple={false}
        ref={passwordRef}
        type="password"
        className="p-1 px-2 border-2 bg-white  w-full"
      />
      <div>
        <Button
          disabled={!(file.width === dim[0] && file.height === dim[1])}
          onClick={uploadImage}
        >
          Change Image
        </Button>
      </div>
    </>
  );
}
