import { useEffect, useState } from "react";
import Button from "../Components/Button";

export default function Modal(props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.open) {
      setOpen(true);
    }
  }, [props.open]);

  return (
    <div className={open ? "block" : "hidden"}>
      <div className="fixed z-10 left-0 top-0 w-screen h-screen bg-transparentblack">
        <div className="bg-green-50 w-[80%] md:w-[60%] lg:w-[40%] mt-[13%] mx-auto border-2 p-4 space-y-4">
          <h2 className="text-2xl font-normal">{props.title}</h2>
          <div>{props.children}</div>
          <div className="p-2 flex flex-row justify-end">
            <Button onClick={() => setOpen(false)}>close</Button>
          </div>
        </div>
      </div>
    </div>
  );
}
