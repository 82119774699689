import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingAnimation from "../Components/LoadingAnimation";
import { Config } from "../Config";
import Button from "../Components/Button";
import { UserContext } from "../Layout/Main";
import { LuArrowUpRightSquare, LuFileWarning } from "react-icons/lu";
import SearchBar from "../Components/SearchBar";

export default function ListModules() {
  const [loading, setLoading] = useState(false);
  const [modules, setModules] = useState([]);
  const [arcweave, setArcweave] = useState();
  const user = useContext(UserContext);
  const [search, setSearch] = useState("");

  const { filter, filterId } = useParams();

  const topRef = useRef();

  useEffect(() => {
    getModules();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getModules() {
    setLoading(true);

    const response = await fetch(Config.api.module.list);
    let data = await response.json();

    setModules(data);
    setLoading(false);

    const arcweaveRes = await fetch(Config.api.arcweave.modules);

    const arcResData = await arcweaveRes.json();
    console.log(arcResData);
    setArcweave(arcResData);

    if (filter) {
      data = data.filter((item) => item[filter] === filterId);
    }
  }

  async function handleArcweaveButton(id) {
    const boardId = arcweave?.boards[id]?.children[0];
    const url = `https://arcweave.com/app/project/MJ0972kEOe?board=${boardId}`;
    window.open(url, "_blank").focus();
  }

  return (
    <>
      <h1 ref={topRef}>Modules</h1>
      <p>Alle Modules are listed here.</p>

      <h2>
        {filter ? (
          <>
            [{filter}: {filterId}]
          </>
        ) : null}
      </h2>

      <LoadingAnimation loading={loading} />

      <SearchBar onChange={(e) => setSearch(e.target.value)} />

      <table className="table-auto w-full border-2 border-slate-300 text-left">
        <thead className="border-2 border-slate-300">
          <tr>
            <th className="p-2">Screenshot</th>
            <th className="p-2">Preview</th>
            <th className="p-2">Module name</th>
            <th className="p-2">Description</th>
            <th className="p-2">Arcweave Directory</th>
            <th className="p-2">Edit</th>
          </tr>
        </thead>
        <tbody>
          {modules
            .filter(
              (module) =>
                module.dir?.toLowerCase().includes(search.toLowerCase()) ||
                module.subDir?.toLowerCase().includes(search.toLowerCase())
            )
            .sort((a, b) =>
              (a?.dir + a?.subDir).localeCompare(b?.dir + b?.subDir)
            )
            .map((module) => (
              <tr key={module.moduleId} className="border-2 border-slate-300">
                <td className="p-2 relative">
                  <div className="aspect-video h-40 bg-green-200 ">
                    <img
                      loading="lazy"
                      alt="screenshot"
                      src={`https://images.career-captain.de/screenshots/${module.dir}_${module.subDir}.png`}
                    />
                  </div>
                  <div className="absolute bottom-4 right-4">
                    <Button to={`../imagesmodulescreenshot/${module.moduleId}`}>
                      Change
                    </Button>
                  </div>
                </td>

                <td className="p-2 relative">
                  <div className="aspect-video h-40 bg-green-200">
                    <img
                      loading="lazy"
                      alt="preview"
                      src={`https://images.career-captain.de/module/${module.dir}_${module.subDir}.png`}
                    />
                  </div>
                  <div className="absolute bottom-4 right-4">
                    <Button to={`../imagesmodulepreview/${module.moduleId}`}>
                      Change
                    </Button>
                  </div>
                </td>

                <td className="p-2">{module.name}</td>
                <td className="p-2">{module.description}</td>

                <td className="p-2 space-y-4">
                  {arcweave?.boards && !arcweave?.boards[module.moduleId] && (
                    <div className="flex items-center gap-2 bg-red-600 text-white p-2 w-fit rounded-md text-xl">
                      <LuFileWarning /> No Arcweave board found
                    </div>
                  )}

                  <div>
                    {module.dir}/{module.subDir}
                  </div>

                  {arcweave?.boards && (
                    <button
                      disabled={!arcweave?.boards[module.moduleId]}
                      onClick={() => handleArcweaveButton(module.moduleId)}
                      className="flex items-center gap-2 bg-green-600 disabled:bg-gray text-white p-2 rounded-md text-xl"
                    >
                      <img
                        src="https://arcweave.com/favicon-32x32.png"
                        alt="arcweave icon"
                      />
                      <LuArrowUpRightSquare />
                    </button>
                  )}
                </td>

                <td className="p-2">
                  <Button to={`../editmodule/${module.moduleId}`}>Edit</Button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {user.permissionLevel >= 2 && (
        <>
          <h2>Create</h2>
          <p>If you want to create a new module, click here: </p>
          <p>
            <Button to="/createmodule">Create a module</Button>
          </p>
        </>
      )}
    </>
  );
}
