import { BrowserRouter } from "react-router-dom";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { createContext, useEffect, useState } from "react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";

import Navigation from "./Navigation";
import AllRoutes from "./AllRoutes";
import { userConfig } from "../userConfig";

export const UserContext = createContext();

export default function Main() {
  const { instance, inProgress, accounts } = useMsal();
  const [apitoken, setApitoken] = useState(null);
  const [permissionLevel, setPermissionLevel] = useState(null);

  async function signIn() {
    if (accounts.length === 0) {
      await instance.loginRedirect(loginRequest).catch((error) => {
        console.log(error);
      });
    }
  }

  function checkPermissionLevel(username) {
    if (userConfig.level3.includes(username)) {
      setPermissionLevel(3);
      return;
    }
    if (userConfig.level2.includes(username)) {
      setPermissionLevel(2);
      return;
    }
    if (userConfig.level1.includes(username)) {
      setPermissionLevel(1);
      return;
    }
    setPermissionLevel(0);
  }

  useEffect(() => {
    if (accounts.length !== 0) {
      const accessTokenRequest = {
        scopes: ["api://ab6b9387-4d81-4656-a530-ee097fd0986e/Api.Access"],
        account: accounts[0],
      };

      checkPermissionLevel(accounts[0].username);

      if (!apitoken && inProgress === InteractionStatus.None) {
        instance
          .acquireTokenSilent(accessTokenRequest)
          .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;
            // Call your API with token
            setApitoken(accessToken);
          })
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              instance.acquireTokenRedirect(accessTokenRequest);
            }
            console.log(error);
          });
      }
    }
  }, [instance, accounts, inProgress, apitoken]);

  return (
    <BrowserRouter>
      <>
        <UnauthenticatedTemplate>
          <div className="text-3xl h-screen grid place-items-center">
            <div className="flex flex-col gap-8">
              <img
                src="https://images.career-captain.de/static/logos/CareerCaptainLogo.svg"
                className="w-1/3 mx-auto"
                alt="Career Captain Logo"
              />
              You need to be logged in to access the page!
              <button
                onClick={signIn}
                className="bg-green-600 rounded-lg text-white p-8 w-1/2 mx-auto"
              >
                Login
              </button>
            </div>
          </div>
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <UserContext.Provider value={{ permissionLevel: permissionLevel }}>
            <div className="flex flex-row w-screen h-screen bg-green-50 text-slate-900 text-sm">
              <div className="flex flex-col w-full h-full">
                <h1 className="text-xl p-4 border-b-2 border-slate-300">
                  Career Captain Dashboard
                </h1>
                <div className="flex h-full overflow-hidden">
                  <Navigation />
                  <div className="w-full h-full overflow-y-scroll">
                    <div className="p-4 space-y-3">
                      <AllRoutes tok={apitoken} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </UserContext.Provider>
        </AuthenticatedTemplate>
      </>
    </BrowserRouter>
  );
}
