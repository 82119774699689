import { useState } from "react";
import Button from "../Components/Button";
import { Config } from "../Config";

export default function Jenkins() {
  const [clickedStart, setClickedStart] = useState(false);
  const [clickedStop, setClickedStop] = useState(false);

  async function changeVM(start) {
    if (start) {
      setClickedStart(true);
    } else {
      setClickedStop(true);
    }

    await fetch(start ? Config.jenkins.start : Config.jenkins.stop, {
      method: "POST",
      mode: "no-cors",
    });
  }

  return (
    <>
      <h1>Jenkins</h1>
      <p>Here you can start the Jenkins VM.</p>
      <h2>Status</h2>
      <p>
        If the following website is accessible, the virtual machine is online.
      </p>
      <a
        className="text-blue-600"
        href="http://jenkins.career-captain.de:8080/login"
        target="_blank"
        rel="noreferrer"
      >
        Open Page in new tab
      </a>

      <h2>Start</h2>
      <p>If you want to start the Jenkins VM, click here: </p>
      <p>This takes between 1 and 10 minutes</p>
      <p>
        <Button disabled={clickedStart} onClick={() => changeVM(true)}>
          Start VM
        </Button>
      </p>
      <h2>Stop</h2>
      <p>If you want to stop the Jenkins VM, click here: </p>
      <p>This takes between 1 and 10 minutes</p>
      <p>
        <Button disabled={clickedStop} onClick={() => changeVM(false)}>
          Stop VM
        </Button>
      </p>
    </>
  );
}
