export const userConfig = {
  level3: [
    "maximilian.anzinger@career-captain.de",
    "felix.reithmaier@career-captain.de",
  ],
  level2: [
    "maximilian.hau@career-captain.de",
    "sebastian.hau@career-captain.de",
    "sofia.gutoranska@career-captain.de",
    "benjamin.seifert@career-captain.de",
    "leander.renholzberger@career-captain.de",
  ],
  level1: ["thomas.stadler@career-captain.de"],
};
