import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../Components/Button";
import Legend from "../Components/Legend";
import LoadingAnimation from "../Components/LoadingAnimation";
import { Config } from "../Config";
import { UserContext } from "../Layout/Main";

export default function ListJobs() {
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);

  const user = useContext(UserContext);

  const { filter, filterId } = useParams();

  useEffect(() => {
    getJobs();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getJobs() {
    setLoading(true);

    const response = await fetch(Config.api.job.list);
    let data = await response.json();

    if (filter) {
      data = data.filter((item) => item[filter] === filterId);
    }

    setJobs(data);

    setLoading(false);
  }

  return (
    <>
      <h1>Jobs</h1>
      <p>All jobs are listed here.</p>
      <h2>
        {filter ? (
          <>
            [{filter}: {filterId}]
          </>
        ) : null}
      </h2>
      <LoadingAnimation loading={loading} />
      <table className="table-auto w-full border-2 border-slate-300 text-left">
        <thead className="border-2 border-slate-300">
          <tr>
            <th className="p-2">Copy Id</th>

            <th className="p-2">Customer</th>
            <th className="p-2">Job name</th>
            <th className="p-2">World</th>
            {user.permissionLevel > 0 && (
              <>
                <th className="p-2">Images</th>
                <th className="p-2">Job</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {jobs
            ?.sort((a, b) =>
              a.displayName
                ?.toLowerCase()
                .localeCompare(b.displayName?.toLowerCase())
            )
            ?.map((job) => (
              <tr
                key={job.jobId}
                className={
                  job.customerId === "00000000-0000-0000-0000-000000000000"
                    ? "bg-yellow-100"
                    : null
                }
              >
                <td className="p-2">
                  <Button
                    onClick={() => navigator.clipboard.writeText(job.jobId)}
                  >
                    Copy Id
                  </Button>
                </td>
                <td className="p-2">{job.customerDisplayName}</td>
                <td className="p-2">{job.displayName}</td>

                <td className="p-2">
                  <Button to={`../showjob/${job.jobId}`}>Open Job</Button>
                </td>
                {user.permissionLevel > 0 && (
                  <>
                    <td className="p-2">
                      <Button to={`../imagesjob/${job.jobId}`}>Change</Button>
                    </td>
                    <td className="p-2">
                      <Button to={`../editjob/${job.jobId}`}>Edit</Button>
                    </td>
                  </>
                )}
              </tr>
            ))}
        </tbody>
      </table>

      <h2>Legend</h2>
      <Legend>job of a customer</Legend>
      <Legend color="bg-yellow-100">generic job</Legend>

      {user.permissionLevel >= 1 && filter === "customerId" && filterId && (
        <>
          <h2>Create</h2>
          <p>Create a new Job here: </p>

          <p>
            <Button to={`/createjob/${filterId}`}>Create job</Button>
          </p>
        </>
      )}
    </>
  );
}
