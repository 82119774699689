export default function Home() {
  return (
    <>
      <h1>Home</h1>
      <p>Welcome to the Career Captain staging page.</p>
      <p>
        Use this tool to create new Customers, update their information and
        create/edit jobs
      </p>
      <p>
        Changes are immediately applied to the website. (except on staging
        worlds)
      </p>
      <br />
      <p>How to create a new Customer with one or more jobs</p>
      <ol className="list-decimal list-inside">
        <li>
          Go to Customer &gt; Create Customer &gt; Enter the comapnies Name
        </li>
        <li>
          Editing the Customer &gt; Provide all the information that you
          obtained from or about the customer
        </li>
        <li>
          Go to Jobs &gt; Create Job &gt; Select the customer and the
          corresponding World
        </li>
        <li>
          Editing the Job &gt; Provide all possible information about the job
        </li>
      </ol>
      <p>Done, if you need more jobs or customers, simply repeat the process</p>
    </>
  );
}
