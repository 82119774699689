import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Config, jwtExpiredStatus } from "../Config";
import Button from "../Components/Button";
import Modal from "../Components/Modal";
import { Checkbox, Input, Textarea } from "../Components/Inputs";
import LoadingAnimation from "../Components/LoadingAnimation";
import checkJwtExpiration from "../Functions/checkJwtExpiration";
import { LuTrash, LuPlus } from "react-icons/lu";

export default function EditWorld(props) {
  const { worldId } = useParams();

  const [world, setWorld] = useState();
  const [modules, setModules] = useState();

  const [arcweaveProjects, setArcweaveProjects] = useState([]);
  const [arcweaveBoards, setArcweaveBoards] = useState();

  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState({ code: 0, message: "", body: "" });

  useEffect(() => {
    if (!props.token) return;
    getData();
  }, [props.token]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!world?.arcweaveId) return;
    if (!props.token) return;

    fetch(Config.api.arcweave.project.replace("{id}", world.arcweaveId), {
      headers: {
        Authorization: `Bearer ${props.token}`,
      },
    }).then((response) => {
      response.json().then((data) => {
        const moduleIds = Object.keys(data.boards)
          .map((key) => data.boards[key])
          .find((el) => el.root).children;
        setArcweaveBoards(
          moduleIds.map((id) => ({
            id: id,
            name: data.boards[id].name,
          }))
        );
      });
    });
  }, [world, props.token]);

  async function getData() {
    setLoading(true);

    const response = await fetch(Config.api.world.get.replace("{id}", worldId));
    const data = await response.json();
    setWorld(data);

    if (!checkJwtExpiration(props.token)) {
      setStatus(jwtExpiredStatus);
      setLoading(false);
      return;
    }

    const responseArcweave = await fetch(Config.api.arcweave.list, {
      headers: {
        Authorization: `Bearer ${props.token}`,
      },
    });
    const dataArcweave = await responseArcweave.json();
    setArcweaveProjects(dataArcweave);

    const responseModules = await fetch(Config.api.module.list);
    const dataModules = await responseModules.json();
    setModules(dataModules.sort((a, b) => a.dir.localeCompare(b.dir)));

    setLoading(false);
  }

  async function changeWorld() {
    setLoading(true);

    if (!checkJwtExpiration(props.token)) {
      setStatus(jwtExpiredStatus);
      setLoading(false);
      return;
    }

    const response = await fetch(
      Config.api.world.changevalues.replace("{id}", worldId),
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${props.token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(world),
      }
    );

    setLoading(false);
    let responsetext = await response.text();

    setStatus({
      code: response.status,
      text: response.statusText,
      body: responsetext,
    });
  }

  return (
    <>
      <h1>Edit World</h1>
      <p>Here you can change staging and production state of a world.</p>

      <LoadingAnimation loading={loading} />
      {
        <Modal
          open={status.code !== 0 && !loading}
          title={status.code + ": " + status.text}
        >
          {status.body}
        </Modal>
      }

      <h2>Information</h2>

      <p>World folder:</p>
      <p className="bg-cclightgreen w-fit p-1 px-2 border-2 border-ccdarkgreen">
        {world?.customerName}/{world?.worldName}
      </p>

      <h2>Change State</h2>
      <p>Explaination of the different states:</p>
      <ul>
        <li>Staging = the world is shown in our testing page</li>
        <li>Production = this world is already published to everyone</li>
        <li>Preview = this world is pubslished in our school page</li>
      </ul>
      <div className="grid grid-cols-3 gap-2">
        <Checkbox
          id="isStaging"
          setState={setWorld}
          state={world}
          label="is staging"
        />
        <Checkbox
          id="isProduction"
          setState={setWorld}
          state={world}
          label="is production"
        />
        <Checkbox
          id="isPreview"
          setState={setWorld}
          state={world}
          label="is preview"
        />
      </div>

      <h2>Change Dialog Date (deprecated)</h2>
      <p>
        Set the date from which the Arcweave dialog file should be loaded. This
        only affects the Production world.
      </p>

      <Input
        type="date"
        id="arcweaveDate"
        state={world}
        setState={setWorld}
        label="arcweave version date"
      />

      <h2>Arcweave Boards (important!)</h2>
      <div>
        <label htmlFor="arcweaveId">Arcweave project id</label>
        <select
          value={world?.arcweaveId}
          id="arcweaveId"
          className="border-2 p-1 w-full"
          onChange={(e) => setWorld({ ...world, arcweaveId: e.target.value })}
        >
          <option value={""}>-</option>
          {arcweaveProjects.map((el) => (
            <option key={el.hash} value={el.hash}>
              {el.name}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label htmlFor="arcweaveBoards">Arcweave project id</label>
        <select
          value={world?.arcweaveBoards}
          id="arcweaveBoards"
          className="border-2 p-1 w-full"
          onChange={(e) =>
            setWorld({ ...world, arcweaveBoards: e.target.value })
          }
        >
          <option value={""}>-</option>
          {arcweaveBoards?.map((el) => (
            <option key={el.id} value={el.id}>
              {el.name}
            </option>
          ))}
        </select>
      </div>

      <h2>Module Boards (important!)</h2>
      <p>Set the dialog boards of the career captain modules.</p>

      {modules && (
        <>
          {world?.arcweaveModuleBoards
            ?.split(",")
            .filter((el) => el)
            .map((el) => (
              <div className="flex gap-2" key={el}>
                <button
                  onClick={() =>
                    setWorld({
                      ...world,
                      arcweaveModuleBoards: [
                        ...world.arcweaveModuleBoards?.split(","),
                      ]
                        .filter((id) => id !== el)
                        .join(","),
                    })
                  }
                >
                  <LuTrash size={20} />
                </button>
                <input
                  disabled
                  className="border-2 p-1 w-full"
                  readOnly
                  value={`${modules.find((m) => m.moduleId === el)?.dir} - ${
                    modules.find((m) => m.moduleId === el)?.subDir
                  }`}
                />
              </div>
            ))}

          <p>add new module board</p>
          <div>
            <div className="flex gap-2 items-center">
              <LuPlus size={20} />

              <select
                className="border-2 p-1 w-full"
                onChange={(e) =>
                  setWorld({
                    ...world,
                    arcweaveModuleBoards: [
                      ...(world.arcweaveModuleBoards?.split(",") || []),
                      e.target.value,
                    ].join(","),
                  })
                }
              >
                <option value={""}>-</option>
                {modules
                  .filter(
                    (el) => !world?.arcweaveModuleBoards?.includes(el.moduleId)
                  )
                  .map((el) => (
                    <option key={el.moduleId} value={el.moduleId}>
                      {el.dir + " - " + el.subDir}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </>
      )}

      <h2>World Information</h2>
      <p>For fictional worlds, texts will be shown in demo worlds.</p>

      <Input
        id="displayName"
        state={world}
        setState={setWorld}
        label="Display Name / Title"
        placeholder="Job-Title (m/w/d)"
      />

      <Textarea
        maxLength={250}
        id="description"
        state={world}
        setState={setWorld}
        label="Description of the World"
        placeholder="In this job ..."
      />
      <br />
      <div>
        <Button onClick={changeWorld}>Change Values</Button>
      </div>
    </>
  );
}
