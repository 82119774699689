import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../Components/Button";
import LoadingAnimation from "../Components/LoadingAnimation";
import { Config } from "../Config";
import { UserContext } from "../Layout/Main";

export default function ListInformation() {
  const [loading, setLoading] = useState(false);
  const [information, setInformation] = useState([]);

  const user = useContext(UserContext);

  const { filter, filterId } = useParams();

  useEffect(() => {
    getInformation();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getInformation() {
    setLoading(true);

    const response = await fetch(Config.api.information.list);
    let data = await response.json();

    if (filter) {
      data = data.filter((item) => item[filter] === filterId);
    }

    data.sort((a, b) =>
      a.customerDisplayName.localeCompare(b.customerDisplayName)
    );

    setInformation(data);
    setLoading(false);
  }

  return (
    <>
      <h1>Informations</h1>
      <p>These are the informations points of a customer.</p>

      <h2>
        {filter ? (
          <>
            [{filter}: {filterId}]
          </>
        ) : null}
      </h2>

      <LoadingAnimation loading={loading} />

      <table className="table-auto w-full border-2 border-slate-300 text-left">
        <thead className="border-2 border-slate-300">
          <tr>
            <th className="p-2">Copy Id</th>
            <th className="p-2">Customer name</th>
            <th className="p-2">Display name</th>
            <th className="p-2">URL</th>
            <th className="p-2">Information</th>
          </tr>
        </thead>
        <tbody>
          {information.map((inf) => (
            <tr key={inf.informationId}>
              <td className="p-2">
                <Button
                  onClick={() =>
                    navigator.clipboard.writeText(inf.informationId)
                  }
                >
                  Copy Id
                </Button>
              </td>
              <td className="p-2">{inf.customerDisplayName}</td>
              <td className="p-2">{inf.displayName}</td>
              <td className="p-2">{inf.link}</td>

              <td className="p-2">
                <Button to={`../editinformation/${inf.informationId}`}>
                  Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {user.permissionLevel >= 1 && filter === "customerId" && filterId && (
        <>
          <h2>Create</h2>
          <p>Create a new Job here: </p>

          <p>
            <Button to={`/createinformation/${filterId}`}>Create job</Button>
          </p>
        </>
      )}
    </>
  );
}
