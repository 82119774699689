const baseUrl = "https://api.career-captain.de/v1";

export const jwtExpiredStatus = {
  code: 401,
  text: "Invalid session",
  body: "Your login is no longer valid because it has been too long since you logged in. Reload the page to start a new session.",
};

export const Config = {
  jenkins: {
    status: `http://jenkins.career-captain.de:8080/login`,
    start: `https://57b8d321-7ade-47ad-88ae-6044dbfadb7c.webhook.we.azure-automation.net/webhooks?token=FdIWYsr%2bDEZjCMPYaOdib8IF56kTzi5XOdd1csh3qxg%3d`,
    stop: `https://57b8d321-7ade-47ad-88ae-6044dbfadb7c.webhook.we.azure-automation.net/webhooks?token=5C5kZFyqGfQLx54ZsDTTej1ZZHRqMM0RDGa4beXdR%2bo%3d`,
  },
  api: {
    file: {
      image: `${baseUrl}/file/upload-image`,
    },
    tracking: {
      getActions: `${baseUrl}/tracking/get-stats/action`,
    },
    arcweave: {
      modules: `${baseUrl}/arcweave/getjson/MJ0972kEOe`,
      list: `${baseUrl}/arcweave/list`,
      project: `${baseUrl}/arcweave/project/{id}`,
      allModules: `${baseUrl}/arcweave/all-modules`,
    },
    customer: {
      create: `${baseUrl}/customer/create`,
      list: `${baseUrl}/customer/list-all`,
      get: `${baseUrl}/customer/{id}`,
      delete: `${baseUrl}/customer/{id}`,
      changevalues: `${baseUrl}/customer/{id}/change-values`,
      data: `${baseUrl}/customer/{id}/data`,
    },
    world: {
      create: `${baseUrl}/world/create`,
      list: `${baseUrl}/world/list`,
      get: `${baseUrl}/world/{id}`,
      move: `${baseUrl}/world/{id}/move`,
      changevalues: `${baseUrl}/world/{id}/change-values`,
    },
    person: {
      create: `${baseUrl}/person/create`,
      list: `${baseUrl}/person/list`,
      get: `${baseUrl}/person/{id}`,
      delete: `${baseUrl}/person/{id}`,
      changevalues: `${baseUrl}/person/{id}/change-values`,
    },
    job: {
      create: `${baseUrl}/job/create`,
      list: `${baseUrl}/job/list-all`,
      get: `${baseUrl}/job/{id}`,
      data: `${baseUrl}/job/{id}/data`,
      delete: `${baseUrl}/job/{id}`,
      changevalues: `${baseUrl}/job/{id}/change-values`,
    },
    information: {
      create: `${baseUrl}/information/create`,
      list: `${baseUrl}/information/list`,
      get: `${baseUrl}/information/{id}`,
      delete: `${baseUrl}/information/{id}`,
      changevalues: `${baseUrl}/information/{id}/change-values`,
    },
    previewmodule: {
      create: `${baseUrl}/previewmodule/create`,
      list: `${baseUrl}/previewmodule/list`,
      get: `${baseUrl}/previewmodule/{id}`,
      delete: `${baseUrl}/previewmodule/{id}`,
    },
    module: {
      create: `${baseUrl}/module/create`,
      list: `${baseUrl}/module/list`,
      get: `${baseUrl}/module/{id}`,
      delete: `${baseUrl}/module/{id}`,
      changevalues: `${baseUrl}/module/{id}/change-values`,
    },
    permission: {
      create: `${baseUrl}/permission/create`,
      list: `${baseUrl}/permission/list`,
      get: `${baseUrl}/permission/{id}`,
      listusers: `${baseUrl}/permission/get-users`,
      delete: `${baseUrl}/permission/delete`,
      getnames: `${baseUrl}/permission/get-recource-names/{id}`,
    },
  },
};
