import { Route, Routes } from "react-router-dom";
import CreateCustomer from "../Pages/CreateCustomer";
import CreateJob from "../Pages/CreateJob";
import EditPermission from "../Pages/EditPermission";
import CreatePerson from "../Pages/CreatePerson";
import EditCustomer from "../Pages/EditCustomer";
import EditJob from "../Pages/EditJob";
import EditPerson from "../Pages/EditPerson";
import EditWorld from "../Pages/EditWorld";
import Home from "../Pages/Home";
import ImagesCustomer from "../Pages/ImagesCustomer";
import ImagesJob from "../Pages/ImagesJob";
import ImagesWorld from "../Pages/ImagesWorld";
import ListCustomer from "../Pages/ListCustomer";
import ListJobs from "../Pages/ListJobs";
import ListPersons from "../Pages/ListPersons";
import ListUsers from "../Pages/ListUsers";
import ListWorlds from "../Pages/ListWorlds";
import MoveWorld from "../Pages/MoveWorld";
import ListInformation from "../Pages/ListInformation";
import EditInformation from "../Pages/EditInformation";
import CreateInformation from "../Pages/CreateInformation";
import ShowJob from "../Pages/Showjob";
import ShowCustomer from "../Pages/ShowCustomer";
import Jenkins from "../Pages/Jenkins";
import ShowPreviewModule from "../Pages/ShowPreviewModule";
import CreatePreviewModule from "../Pages/CreatePreviewModule";
import EditPreviewModule from "../Pages/EditPreviewModule";
import ListPreviewModules from "../Pages/ListPreviewModules";
import ListModules from "../Pages/ListModules";
import EditModule from "../Pages/EditModule";
import ImagesModuleScreenshot from "../Pages/ImagesModuleScreenshot";
import CreateModule from "../Pages/CreateModule";
import Stats from "../Pages/Stats";
import ImagesModulePreview from "../Pages/ImagesModulePreview";
import StatsAction from "../Pages/StatsAction";

export default function AllRoutes({ tok }) {
  return (
    <Routes>
      <Route path="" element={<Home />} />

      <Route path="jenkins" element={<Jenkins />} />

      <Route path="stats" element={<Stats />} />
      <Route path="stats/action" element={<StatsAction token={tok} />} />

      <Route path="customer" element={<ListCustomer />}>
        <Route path=":filter=:filterId" element={<ListCustomer />} />
      </Route>

      <Route path="information" element={<ListInformation />}>
        <Route path=":filter=:filterId" element={<ListInformation />} />
      </Route>

      <Route path="users" element={<ListUsers />}>
        <Route path=":filter=:filterId" element={<ListUsers />} />
      </Route>

      <Route path="previewmodules" element={<ListPreviewModules />}>
        <Route path=":filter=:filterId" element={<ListPreviewModules />} />
      </Route>

      <Route path="modules" element={<ListModules />}>
        <Route path=":filter=:filterId" element={<ListModules />} />
      </Route>

      <Route path="job" element={<ListJobs />}>
        <Route path=":filter=:filterId" element={<ListJobs />} />
      </Route>

      <Route path="world" element={<ListWorlds />}>
        <Route path=":filter=:filterId" element={<ListWorlds />} />
      </Route>

      <Route path="person" element={<ListPersons />}>
        <Route path=":filter=:filterId" element={<ListPersons />} />
      </Route>

      <Route path="imagesjob/:jobId" element={<ImagesJob />} />

      <Route path="imagescustomer/:customerId" element={<ImagesCustomer />} />

      <Route path="imagesworld/:worldId" element={<ImagesWorld />} />

      <Route
        path="/imagesmodulescreenshot/:moduleId"
        element={<ImagesModuleScreenshot token={tok} />}
      />
      <Route
        path="/imagesmodulepreview/:moduleId"
        element={<ImagesModulePreview token={tok} />}
      />

      <Route path="/editjob/:jobId" element={<EditJob token={tok} />} />
      <Route
        path="/editpreviewmodule/:previewmoduleId"
        element={<EditPreviewModule token={tok} />}
      />
      <Route path="/editworld/:worldId" element={<EditWorld token={tok} />} />
      <Route
        path="/editinformation/:informationId"
        element={<EditInformation token={tok} />}
      />
      <Route
        path="/editcustomer/:customerId"
        element={<EditCustomer token={tok} />}
      />

      <Route
        path="/editperson/:personId"
        element={<EditPerson token={tok} />}
      />

      <Route
        path="/editpermission/:userId"
        element={<EditPermission token={tok} />}
      />

      <Route
        path="/createperson/:customerId"
        element={<CreatePerson token={tok} />}
      />

      <Route
        path="/createinformation/:customerId"
        element={<CreateInformation token={tok} />}
      />

      <Route
        path="/createjob/:customerId"
        element={<CreateJob token={tok} />}
      />

      <Route path="/createcustomer/" element={<CreateCustomer token={tok} />} />

      <Route path="/showjob/:jobId" element={<ShowJob />} />

      <Route
        path="/editmodule/:moduleId"
        element={<EditModule token={tok} />}
      />
      <Route path="/createmodule/" element={<CreateModule token={tok} />} />
      <Route
        path="/createpreviewmodule/"
        element={<CreatePreviewModule token={tok} />}
      />
      <Route
        path="/showpreviewmodule/:previewmoduleId"
        element={<ShowPreviewModule />}
      />

      <Route path="/showcustomer/:customerId" element={<ShowCustomer />} />

      <Route path="/moveworld/:worldId" element={<MoveWorld token={tok} />} />
    </Routes>
  );
}
