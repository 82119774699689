import { createRoot } from "react-dom/client";
import Main from "./Layout/Main";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { StrictMode } from "react";
import { msalConfig } from "./authConfig";

import "./index.css";

const msalInstance = new PublicClientApplication(msalConfig);

const root = createRoot(document.getElementById("root"));

root.render(
  <StrictMode>
    <MsalProvider instance={msalInstance}>
      <Main />
    </MsalProvider>
  </StrictMode>
);
