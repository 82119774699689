import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Config, jwtExpiredStatus } from "../Config";
import Button from "../Components/Button";
import Modal from "../Components/Modal";
import LoadingAnimation from "../Components/LoadingAnimation";
import { UserContext } from "../Layout/Main";
import checkJwtExpiration from "../Functions/checkJwtExpiration";

function isImgAvailable(dir, subDir) {
  var http = new XMLHttpRequest();
  http.open(
    "HEAD",
    `https://images.career-captain.de/screenshots/${dir}_${subDir}.png`,
    false
  );
  http.send();

  var http2 = new XMLHttpRequest();
  http2.open(
    "HEAD",
    `https://images.career-captain.de/module/${dir}_${subDir}.png`,
    false
  );
  http2.send();

  return http.status !== 404 && http2.status !== 404;
}

export default function EditModule(props) {
  const user = useContext(UserContext);

  const { moduleId } = useParams();

  const [showDelete, setShowDelete] = useState(false);

  const [module, setModule] = useState();

  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState({ code: 0, message: "", body: "" });
  const [imgPresent, setImgPresent] = useState(false);

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getData() {
    setLoading(true);
    const response = await fetch(
      Config.api.module.get.replace("{id}", moduleId)
    );
    const data = await response.json();
    setModule(data);
    setImgPresent(isImgAvailable(data.dir, data.subDir));
    setLoading(false);
  }

  async function changeModule() {
    setLoading(true);

    if (!checkJwtExpiration(props.token)) {
      setStatus(jwtExpiredStatus);
      setLoading(false);
      return;
    }

    const response = await fetch(
      Config.api.module.changevalues.replace("{id}", moduleId),
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${props.token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(module),
      }
    );

    setLoading(false);
    setStatus({
      code: response.status,
      text: response.statusText,
      body: await response.text(),
    });
  }

  async function deleteModule() {
    setLoading(true);

    if (!checkJwtExpiration(props.token)) {
      setStatus(jwtExpiredStatus);
      setLoading(false);
      return;
    }

    const response = await fetch(
      Config.api.module.delete.replace("{id}", module.moduleId),
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${props.token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    setLoading(false);
    setStatus({
      code: response.status,
      text: response.statusText,
      body: await response.text(),
    });
  }

  return loading ? (
    <LoadingAnimation loading={loading} />
  ) : (
    <>
      <h1>Edit Module</h1>
      <p>Edit a module</p>

      <Modal
        open={status.code !== 0 && !loading}
        title={status.code + ": " + status.text}
      >
        {status.body}
        <br />
        <br />
        {status.body.includes("deleted module") && (
          <p>
            <Button to="/modules/">Back to modules</Button>
          </p>
        )}
      </Modal>

      <h2>Information</h2>

      <p>Arcweave module folder:</p>
      <p className="bg-cclightgreen w-fit p-1 px-2 border-2 border-ccdarkgreen">
        {module?.dir}/{module?.subDir}
      </p>

      <h2>Change Text</h2>

      <div>
        <p>Display Name / Title</p>
        <input
          value={module?.name}
          onChange={(e) => setModule({ ...module, name: e.target.value })}
          className="border-2 p-1 w-full"
        />
      </div>

      <div>
        <p>Description of the Module</p>
        <textarea
          rows={3}
          value={module?.description}
          onChange={(e) =>
            setModule({ ...module, description: e.target.value })
          }
          className="border-2 p-1 w-full"
          placeholder="In this module ..."
        />
      </div>

      <h2>Change State</h2>

      <div>
        <p>Is in development</p>
        {module?.name && module?.description && imgPresent ? (
          <input
            type="checkbox"
            checked={module?.isDevelopment}
            onChange={(e) =>
              setModule({ ...module, isDevelopment: e.target.checked })
            }
          />
        ) : (
          <p>
            only possible if name, description and and all images are available
          </p>
        )}
      </div>

      <h2>Change Category</h2>

      <p>Category</p>
      <select
        className="border-2 p-1 w-full"
        value={module?.category}
        onChange={(e) => setModule({ ...module, category: e.target.value })}
      >
        {[
          "",
          "Sonstige",
          "Allgemein",
          "Bau",
          "Elektro",
          "Gesundheit",
          "Software",
          "Kaufmännisch",
          "Konstruktion",
          "Hardware",
        ].map((el) => (
          <option key={el} value={el}>
            {el || "-"}
          </option>
        ))}
      </select>

      <br />
      <div>
        <Button onClick={changeModule}>Change Values</Button>
      </div>

      {user.permissionLevel >= 3 && (
        <>
          <h2>Delete module</h2>
          <input
            onChange={(e) => {
              e.target.checked ? setShowDelete(true) : setShowDelete(false);
            }}
            type="checkbox"
            defaultChecked={false}
          />{" "}
          I'm sure I want to delete this module
          <div className={showDelete ? "visible" : "invisible"}>
            <Button onClick={() => deleteModule()}>Delete module</Button>
          </div>
        </>
      )}
    </>
  );
}
