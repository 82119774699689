import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../Components/Button";
import LoadingAnimation from "../Components/LoadingAnimation";
import { Config } from "../Config";
import SearchBar from "../Components/SearchBar";

export default function ListUsers(props) {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");

  const { filter, filterId } = useParams();

  useEffect(() => {
    getJobs();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getJobs() {
    setLoading(true);

    const response = await fetch(Config.api.permission.listusers);
    let data = await response.json();

    if (filter) {
      data = data.filter((item) => item[filter] === filterId);
    }

    setUsers(data);
    setLoading(false);
  }

  return (
    <>
      <h1>Users</h1>
      <p>All users are listed here.</p>
      <h2>
        {filter ? (
          <>
            [{filter}: {filterId}]
          </>
        ) : null}
      </h2>
      <LoadingAnimation loading={loading} />

      <SearchBar onChange={(e) => setSearch(e.target.value)} />

      <table className="table-auto w-full border-2 border-slate-300 text-left">
        <thead className="border-2 border-slate-300">
          <tr>
            <th className="p-2">User ID</th>
            <th className="p-2">Email</th>
            <th className="p-2">Username</th>
            <th className="p-2">Change Permissions</th>
          </tr>
        </thead>
        <tbody>
          {users
            .filter((user) =>
              user.email.toLowerCase().includes(search.toLowerCase())
            )
            .sort((a, b) => a.email.localeCompare(b.email))
            .map((user) => (
              <tr key={user.user_id}>
                <td className="p-2">{user.user_id}</td>
                <td className="p-2">{user.email}</td>
                <td className="p-2">{user.nickname}</td>
                <td className="p-2">
                  <Button to={`../editpermission/${user.user_id}`}>edit</Button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
}
