import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../Components/Button";
import { InputField } from "../Components/Inputs";
import LoadingAnimation from "../Components/LoadingAnimation";
import Modal from "../Components/Modal";
import { Config, jwtExpiredStatus } from "../Config";
import { UserContext } from "../Layout/Main";
import checkJwtExpiration from "../Functions/checkJwtExpiration";

export default function EditPerson(props) {
  const user = useContext(UserContext);

  const { personId } = useParams();

  const refArray = useRef([]);

  const [showDelete, setShowDelete] = useState(false);

  const [person, setPerson] = useState([]);

  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState({ code: 0, message: "", body: "" });

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getData() {
    setLoading(true);

    const response = await fetch(
      Config.api.person.get.replace("{id}", personId)
    );
    const data = await response.json();
    setPerson(data);

    setLoading(false);
  }

  async function changePerson() {
    setLoading(true);

    const bodyjson = JSON.stringify({
      firstName: refArray.current.firstName.value,
      familyName: refArray.current.familyName.value,
      email: refArray.current.email.value,
      phoneNumber: refArray.current.phoneNumber.value,
    });

    const response = await fetch(
      Config.api.person.changevalues.replace("{id}", personId),
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${props.token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: bodyjson,
      }
    );

    setLoading(false);
    let responsetext = await response.text();

    setStatus({
      code: response.status,
      text: response.statusText,
      body: responsetext,
    });
  }

  async function deleteperson() {
    setLoading(true);

    if (!checkJwtExpiration(props.token)) {
      setStatus(jwtExpiredStatus);
      setLoading(false);
      return;
    }

    const response = await fetch(
      Config.api.person.delete.replace("{id}", personId),
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${props.token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    setLoading(false);
    let responsetext = await response.text();

    setStatus({
      code: response.status,
      text: response.statusText,
      body: responsetext,
    });
  }

  return (
    <>
      <h1>Edit Person</h1>
      <p>Here are infos of a person.</p>
      <LoadingAnimation loading={loading} />
      {
        <Modal
          open={status.code !== 0 && !loading}
          title={status.code + ": " + status.text}
        >
          {status.body}
        </Modal>
      }
      <h2>Person [{personId}]</h2>
      <InputField
        id="firstName"
        ref={refArray}
        defaultValue={person.firstName}
        name="firstName"
      />
      <InputField
        id="familyName"
        ref={refArray}
        defaultValue={person.familyName}
        name="familyName"
      />
      <InputField
        id="email"
        ref={refArray}
        defaultValue={person.email}
        name="email"
      />
      <InputField
        id="phoneNumber"
        ref={refArray}
        defaultValue={person.phoneNumber}
        name="phone number"
      />
      <div>
        <Button onClick={() => changePerson()}>Change Values</Button>
      </div>

      {user.permissionLevel >= 3 && (
        <>
          <h2>Delete person</h2>
          <input
            onChange={(e) => {
              e.target.checked ? setShowDelete(true) : setShowDelete(false);
            }}
            type="checkbox"
            defaultChecked={false}
          />{" "}
          I'm sure I want to delete this person
          <div className={showDelete ? "visible" : "invisible"}>
            <Button onClick={() => deleteperson()}>Delete person</Button>
          </div>
        </>
      )}
    </>
  );
}
