import { useEffect, useRef, useState } from "react";
import Button from "../Components/Button";
import LoadingAnimation from "../Components/LoadingAnimation";
import Modal from "../Components/Modal";
import { Config, jwtExpiredStatus } from "../Config";
import { useParams } from "react-router-dom";
import checkJwtExpiration from "../Functions/checkJwtExpiration";

export default function CreateJob(props) {
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState();
  const [worlds, setWorlds] = useState([]);
  const [status, setStatus] = useState({ code: 0, message: "", body: "" });

  const worldRef = useRef();

  const { customerId } = useParams();

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getData() {
    setLoading(true);

    const responseCustomer = await fetch(
      Config.api.customer.get.replace("{id}", customerId)
    );
    setCustomer(await responseCustomer.json());

    const responseWorlds = await fetch(Config.api.world.list);
    setWorlds(await responseWorlds.json());

    setLoading(false);
  }

  async function createJob() {
    setLoading(true);

    const bodyjson = JSON.stringify({
      customerId: customerId,
      worldId: worldRef.current.value,
    });

    if (!checkJwtExpiration(props.token)) {
      setStatus(jwtExpiredStatus);
      setLoading(false);
      return;
    }

    const response = await fetch(Config.api.job.create, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${props.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: bodyjson,
    });

    setLoading(false);
    let responsetext = await response.text();

    setStatus({
      code: response.status,
      text: response.statusText,
      body: responsetext,
    });
  }

  return (
    <>
      <h1>Create a new job</h1>
      <p>Here you can create a new job</p>

      <LoadingAnimation loading={loading} />

      <Modal
        open={status.code !== 0 && !loading}
        title={status.code + ": " + status.text}
      >
        {`Job was sucessfully created! (${status.body})`}
        <br />
        {status.code === 201 ? (
          <p>
            <Button to={"/editjob/" + status.body.slice(1, -1)}>
              Edit job
            </Button>
          </p>
        ) : null}
      </Modal>

      <h2>Create job</h2>

      <p>Selected customer:</p>
      <p className="bg-cclightgreen w-fit p-1 px-2 border-2 border-ccdarkgreen">
        {customer?.displayName}
      </p>

      {/* <p>Select the customer:</p>
      <select
        className="border-2 p-1 w-full"
        ref={customerRef}
        name="customer"
        id="customer"
      >
        <option
          key={"00000000-0000-0000-0000-000000000000"}
          value={"00000000-0000-0000-0000-000000000000"}
        >
          {"Generic Job (no customer will be set)"}
        </option>
        {customer.map((el) => (
          <option key={el.customerId} value={el.customerId}>
            {el.displayName}
          </option>
        ))}
      </select> */}

      <p>Select the world:</p>
      <select
        className="border-2 p-1 w-full"
        ref={worldRef}
        name="world"
        id="world"
      >
        {worlds.map((el) => (
          <option key={el.worldId} value={el.worldId}>
            {el.customerName + "/" + el.worldName}
          </option>
        ))}
      </select>
      <div>
        <Button onClick={createJob}>Create</Button>
      </div>
    </>
  );
}
