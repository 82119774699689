import { Link } from "react-router-dom";

export default function Button({ to, onClick, children, disabled }) {
  return (
    <Link to={to ? to : null}>
      <button
        disabled={disabled}
        onClick={onClick ? onClick : null}
        className={
          // disabled
          //   ? "bg-darkgray text-ccdarkgreen bg-opacity-10 border-2 text-center w-fit p-1 px-3"
          //   :
          "hover:scale-110 bg-green-600 text-white rounded-md shadow-md text-center w-fit p-1 px-3 disabled:bg-gray"
        }
      >
        {children}
      </button>
    </Link>
  );
}
