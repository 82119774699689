import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../Components/Button";
import LoadingAnimation from "../Components/LoadingAnimation";
import { Config } from "../Config";
import { UserContext } from "../Layout/Main";
import SearchBar from "../Components/SearchBar";

export default function ListCustomer() {
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState([]);
  const [showExpired, setShowExpired] = useState(false);
  const [search, setSearch] = useState("");

  const user = useContext(UserContext);

  const { filter, filterId } = useParams();

  useEffect(() => {
    getCustomer();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getCustomer() {
    setLoading(true);

    const response = await fetch(Config.api.customer.list);
    let data = await response.json();

    if (filter) {
      data = data.filter((item) => item[filter] === filterId);
    }

    setCustomer(data);
    setLoading(false);
  }

  return (
    <>
      <h1>Customer</h1>
      <p>All customers are listed here.</p>

      <div className="flex gap-1 w-full items-center">
        <input
          onChange={(e) => setShowExpired(e.target.checked)}
          type="checkbox"
          id={"showExpired"}
          checked={showExpired}
          className="border-2 p-1 w-6 h-6"
        />
        <label htmlFor="showExpired">also show expired Customers</label>
      </div>

      <h2>
        {filter ? (
          <>
            [{filter}: {filterId}]
          </>
        ) : null}
      </h2>

      <LoadingAnimation loading={loading} />

      <SearchBar onChange={(e) => setSearch(e.target.value)} />

      <table className="table-auto w-full border-2 border-slate-300 text-left">
        <thead className="border-2 border-slate-300">
          <tr>
            <th className="p-2">Copy Id</th>
            <th className="p-2">Display name</th>
            <th className="p-2">Customer resources</th>
            <th className="p-2">Start World</th>
            {user.permissionLevel > 0 && (
              <>
                <th className="p-2">Images</th>
                <th className="p-2">Customer</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {customer
            .filter((cu) =>
              cu.displayName.toLowerCase().includes(search.toLowerCase())
            )
            .filter(
              (el) =>
                !el.expiryDate ||
                new Date() < new Date(el.expiryDate) ||
                showExpired
            )
            .map((customer) => (
              <tr key={customer.customerId}>
                <td className="p-2">
                  <Button
                    onClick={() =>
                      navigator.clipboard.writeText(customer.customerId)
                    }
                  >
                    Copy Id
                  </Button>
                </td>
                <td className="p-2">
                  {customer.expiryDate ? (
                    <span
                      className={
                        "mr-2 px-1 " +
                        (new Date() < new Date(customer.expiryDate)
                          ? "bg-green-200"
                          : "bg-red-200")
                      }
                    >
                      {new Date(customer.expiryDate).toLocaleDateString(
                        "de-DE"
                      )}
                    </span>
                  ) : (
                    ""
                  )}

                  {customer.displayName}
                </td>
                <td className="p-2 flex gap-2">
                  <Button to={`../job/customerId=${customer.customerId}`}>
                    jobs
                  </Button>
                  <Button
                    to={`../information/customerId=${customer.customerId}`}
                  >
                    informations
                  </Button>
                  <Button to={`../person/customerId=${customer.customerId}`}>
                    persons
                  </Button>
                </td>
                <td className="p-2">
                  {customer.worldId ? (
                    <Button to={`../showcustomer/${customer.customerId}`}>
                      Open Overworld
                    </Button>
                  ) : null}
                </td>
                {user.permissionLevel > 0 && (
                  <>
                    <td className="p-2">
                      <Button to={`../imagescustomer/${customer.customerId}`}>
                        Change
                      </Button>
                    </td>
                    <td className="p-2">
                      <Button to={`../editcustomer/${customer.customerId}`}>
                        Edit
                      </Button>
                    </td>
                  </>
                )}
              </tr>
            ))}
        </tbody>
      </table>

      {user.permissionLevel >= 1 && (
        <>
          <h2>Create</h2>
          <p>If you want to create a new customer, click here: </p>
          <p>
            <Button to="/createcustomer">Create customer</Button>
          </p>
        </>
      )}
    </>
  );
}
