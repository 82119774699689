import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { UserContext } from "./Main";
import {
  LuFactory,
  LuGlobe2,
  LuServerCog,
  LuUsers,
  LuCodesandbox,
  LuBox,
  LuAreaChart,
} from "react-icons/lu";

function NavElement({ name, perLvl, url, children }) {
  const user = useContext(UserContext);

  return (
    user.permissionLevel >= perLvl && (
      <NavLink
        to={url}
        className={({ isActive }) =>
          "p-2 px-4 rounded-md flex items-center gap-2 text-md " +
          (isActive
            ? "bg-green-600 text-white"
            : "hover:bg-green-600 hover:text-white")
        }
      >
        {children}
        {name}
      </NavLink>
    )
  );
}

export default function Navigation() {
  return (
    <div className="p-4 border-r-2 border-slate-300 flex flex-col gap-2">
      <NavElement perLvl={2} name={"Jenkins"} url={"/jenkins"}>
        <LuServerCog />
      </NavElement>
      <NavElement perLvl={3} name={"Users"} url={"/users"}>
        <LuUsers />
      </NavElement>
      <NavElement perLvl={0} name={"Customer"} url={"/customer"}>
        <LuFactory />
      </NavElement>
      <NavElement perLvl={2} name={"Worlds"} url={"/world"}>
        <LuGlobe2 />
      </NavElement>
      <NavElement perLvl={0} name={"Modules"} url={"/modules"}>
        <LuBox />
      </NavElement>
      <NavElement perLvl={0} name={"Previewmodules"} url={"/previewmodules"}>
        <LuCodesandbox />
      </NavElement>
      <NavElement perLvl={1} name={"Stats"} url={"/stats"}>
        <LuAreaChart />
      </NavElement>
    </div>
  );
}
