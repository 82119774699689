import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../Components/Button";
import { InputArea, InputCheckbox, InputField } from "../Components/Inputs";
import LoadingAnimation from "../Components/LoadingAnimation";
import Modal from "../Components/Modal";
import { Config, jwtExpiredStatus } from "../Config";
import { UserContext } from "../Layout/Main";
import checkJwtExpiration from "../Functions/checkJwtExpiration";

export default function EditJob(props) {
  const user = useContext(UserContext);

  const { jobId } = useParams();

  const refArray = useRef([]);

  const [showDelete, setShowDelete] = useState(false);

  const [job, setJob] = useState([]);
  const [belongingCustomerJobs, SetBelongingCustomerJobs] = useState([]);
  const [persons, setPersons] = useState([]);
  const [worlds, setWorlds] = useState([]);

  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState({ code: 0, message: "", body: "" });

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getData() {
    setLoading(true);

    const response = await fetch(Config.api.job.data.replace("{id}", jobId));
    if (!response.ok) {
      setStatus({
        code: response.status,
        text: response.statusText,
        body: await response.text(),
      });
      setLoading(false);
      return;
    }
    const data = await response.json();
    setJob(data);

    const responsePersons = await fetch(Config.api.person.list);
    const dataPersons = await responsePersons.json();
    setPersons(dataPersons);

    const responseWorlds = await fetch(Config.api.world.list);
    const dataWorlds = await responseWorlds.json();
    setWorlds(dataWorlds);

    if (data.belongingCustomer) {
      const responseBelongingCustomer = await fetch(
        Config.api.customer.data.replace("{id}", data.belongingCustomer)
      );
      const dataBelongingCustomer = await responseBelongingCustomer.json();
      SetBelongingCustomerJobs(dataBelongingCustomer.jobs);
    }

    setLoading(false);
  }

  function emptyToNull(string) {
    if (string === "") {
      return null;
    }
    return string;
  }

  async function changeJob() {
    setLoading(true);

    const bodyjson = JSON.stringify({
      displayName: refArray.current.displayName.value,
      type: refArray.current.type.value,

      graduation: refArray.current.graduation.value,

      isActive: refArray.current.isActive.checked,
      isBranded: refArray.current.isBranded.checked,
      isShortable: refArray.current.isShortable.checked,
      isNavigateBlocked: refArray.current.isNavigateBlocked.checked,

      startDate: emptyToNull(refArray.current.startDate.value),
      deadlineDate: emptyToNull(refArray.current.deadlineDate.value),

      salaryYear1: emptyToNull(refArray.current.salaryYear1.value),
      salaryYear2: emptyToNull(refArray.current.salaryYear2.value),
      salaryYear3: emptyToNull(refArray.current.salaryYear3.value),
      salaryYear4: emptyToNull(refArray.current.salaryYear4.value),

      durationMonths: emptyToNull(refArray.current.durationMonths.value),

      keyword1: refArray.current.keyword1.value,
      keyword2: refArray.current.keyword2.value,
      keyword3: refArray.current.keyword3.value,
      keyword4: refArray.current.keyword4.value,
      keyword5: refArray.current.keyword5.value,

      link: refArray.current.link.value,
      description: refArray.current.description.value,

      personId: emptyToNull(refArray.current.personId.value),
      worldId: emptyToNull(refArray.current.worldId.value),

      belongingJob: emptyToNull(refArray.current.belongingJob?.value),
    });

    if (!checkJwtExpiration(props.token)) {
      setStatus(jwtExpiredStatus);
      setLoading(false);
      return;
    }

    const response = await fetch(
      Config.api.job.changevalues.replace("{id}", jobId),
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${props.token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: bodyjson,
      }
    );

    setLoading(false);
    let responsetext = await response.text();

    setStatus({
      code: response.status,
      text: response.statusText,
      body: responsetext,
    });

    // change style of input if refkey is errormessage
    Object.keys(refArray.current).forEach((key) => {
      if (responsetext.slice(200).includes(key)) {
        refArray.current[key].style.color = "red";
      } else {
        refArray.current[key].style.color = null;
      }
    });
  }

  async function deleteJob() {
    setLoading(true);

    if (!checkJwtExpiration(props.token)) {
      setStatus(jwtExpiredStatus);
      setLoading(false);
      return;
    }

    const response = await fetch(Config.api.job.delete.replace("{id}", jobId), {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${props.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    setLoading(false);
    let responsetext = await response.text();

    setStatus({
      code: response.status,
      text: response.statusText,
      body: responsetext,
    });
  }

  return (
    <>
      <h1>Edit Job</h1>
      <p>Here are infos of a job.</p>
      <LoadingAnimation loading={loading} />
      {
        <Modal
          open={status.code !== 0 && !loading}
          title={status.code + ": " + status.text}
        >
          {status.body}
        </Modal>
      }
      <h2>Job [{jobId}]</h2>

      <InputCheckbox
        id="isNavigateBlocked"
        ref={refArray}
        defaultValue={job.isNavigateBlocked}
        name="Block navigation to this job (job is not ready for publishing)"
      />

      <InputField
        id="displayName"
        ref={refArray}
        defaultValue={job.displayName}
        name="Job name"
      />
      <InputField
        id="type"
        ref={refArray}
        defaultValue={job.type}
        placeholder="Ausbildung"
        name="Type"
      />
      <InputArea
        size={7}
        id="graduation"
        ref={refArray}
        defaultValue={job.graduation}
        placeholder="Abitur, Fachabitur, Mittlere Reife"
        name="Graduation"
      />
      <div className="flex flex-row gap-2">
        <InputCheckbox
          id="isActive"
          ref={refArray}
          defaultValue={job.isActive}
          name="Is Job active"
        />
        <InputCheckbox
          id="isBranded"
          ref={refArray}
          defaultValue={job.isBranded}
          name="Is Job branded"
        />
        <InputCheckbox
          id="isShortable"
          ref={refArray}
          defaultValue={job.isShortable}
          name="Is Shortable"
        />
      </div>
      <div className="flex flex-row gap-2">
        <InputField
          type="date"
          id="startDate"
          ref={refArray}
          defaultValue={job.startDate?.substring(0, 10)}
          name="start date"
        />
        <InputField
          type="date"
          id="deadlineDate"
          ref={refArray}
          defaultValue={job.deadlineDate?.substring(0, 10)}
          name="deadline date"
        />
      </div>
      <div className="flex flex-row gap-2">
        <InputField
          id="salaryYear1"
          ref={refArray}
          defaultValue={job.salaryYear1}
          placeholder="500"
          name="Salary year 1 (rounded, in Euro)"
        />
        <InputField
          id="salaryYear2"
          ref={refArray}
          defaultValue={job.salaryYear2}
          placeholder="600"
          name="Salary year 2 (rounded, in Euro)"
        />
        <InputField
          id="salaryYear3"
          ref={refArray}
          defaultValue={job.salaryYear3}
          placeholder="700"
          name="Salary year 3 (rounded, in Euro)"
        />
        <InputField
          id="salaryYear4"
          ref={refArray}
          defaultValue={job.salaryYear4}
          placeholder="800"
          name="Salary year 4 (rounded, in Euro)"
        />
      </div>
      <div className="flex flex-row gap-2">
        <InputField
          id="keyword1"
          ref={refArray}
          defaultValue={job.keyword1}
          name="Keyword 1"
        />
        <InputField
          id="keyword2"
          ref={refArray}
          defaultValue={job.keyword2}
          name="Keyword 2"
        />
        <InputField
          id="keyword3"
          ref={refArray}
          defaultValue={job.keyword3}
          name="Keyword 3"
        />
        <InputField
          id="keyword4"
          ref={refArray}
          defaultValue={job.keyword4}
          name="Keyword 4"
        />
        <InputField
          id="keyword5"
          ref={refArray}
          defaultValue={job.keyword5}
          name="Keyword 5"
        />
      </div>
      <InputField
        id="link"
        ref={refArray}
        defaultValue={job.link}
        placeholder="https://www.company.com/link-to-the-job"
        name="Job Link"
      />
      <InputField
        id="durationMonths"
        ref={refArray}
        defaultValue={job.durationMonths}
        name="Duration in months"
        placeholder="36"
      />
      <InputArea
        id="description"
        ref={refArray}
        defaultValue={job.description}
        name="Description of the Job"
        placeholder="Als Elektroniker ..."
      />
      <div className="flex flex-col gap-1 w-full">
        <label htmlFor="JobId">Responsible person:</label>
        <select
          className="border-2 p-1 w-full"
          ref={(el) => (refArray.current["personId"] = el)}
          name="personId"
          id="personId"
        >
          <option value={""}>Empty</option>
          {persons.map((el) => (
            <option
              key={el.personId}
              value={el.personId}
              selected={el.personId === job.personId}
            >
              {el.firstName + " " + el.familyName}
            </option>
          ))}
        </select>
      </div>
      {job.belongingCustomer ? (
        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="belongingJob">select a belonging job:</label>
          <select
            className="border-2 p-1 w-full"
            ref={(el) => (refArray.current["belongingJob"] = el)}
            name="belongingJob"
            id="belongingJob"
          >
            <option value={""}>Empty</option>
            {belongingCustomerJobs.map((el) => (
              <option
                key={el.jobId}
                value={el.jobId}
                selected={job.belongingJob === el.jobId}
              >
                {el.displayName}
              </option>
            ))}
          </select>
        </div>
      ) : null}
      <div className="flex flex-col gap-1 w-full">
        <label htmlFor="worldId">World:</label>
        <select
          className="border-2 p-1 w-full"
          ref={(el) => (refArray.current["worldId"] = el)}
          name="worldId"
          id="worldId"
        >
          {worlds.map((el) => (
            <option
              key={el.worldId}
              value={el.worldId}
              selected={el.worldId === job.worldId}
            >
              {el.customerName + " - " + el.worldName}
            </option>
          ))}
        </select>
      </div>
      <div>
        <Button onClick={() => changeJob()}>Update</Button>
      </div>

      {user.permissionLevel >= 3 && (
        <>
          <h2>Delete job</h2>
          <input
            onChange={(e) => {
              e.target.checked ? setShowDelete(true) : setShowDelete(false);
            }}
            type="checkbox"
            defaultChecked={false}
          />{" "}
          I'm sure I want to delete this job
          <div className={showDelete ? "visible" : "invisible"}>
            <Button onClick={() => deleteJob()}>Delete job</Button>
          </div>
        </>
      )}
    </>
  );
}
