import { useEffect, useState } from "react";
import Button from "../Components/Button";
import LoadingAnimation from "../Components/LoadingAnimation";
import Modal from "../Components/Modal";
import { Config, jwtExpiredStatus } from "../Config";
import { LuAlertCircle } from "react-icons/lu";
import checkJwtExpiration from "../Functions/checkJwtExpiration";

export default function CreateCustomer(props) {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ code: 0, message: "", body: "" });

  const [customer, setCustomer] = useState([]);

  const [newDisplayName, setNewDisplayName] = useState("");

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const customerResponse = await fetch(Config.api.customer.list);
    const customerData = await customerResponse.json();
    setCustomer(customerData);
  }

  async function createCustomer() {
    setLoading(true);

    const bodyjson = JSON.stringify({
      displayName: newDisplayName,
    });

    if (!checkJwtExpiration(props.token)) {
      setStatus(jwtExpiredStatus);
      setLoading(false);
      return;
    }

    const response = await fetch(Config.api.customer.create, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${props.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: bodyjson,
    });

    setLoading(false);
    let responsetext = await response.text();

    setStatus({
      code: response.status,
      text: response.statusText,
      body: responsetext,
    });
  }

  return (
    <>
      <h1>Create a new customer</h1>
      <p>Here you can create a new customer</p>

      <LoadingAnimation loading={loading} />

      <Modal
        open={status.code !== 0 && !loading}
        title={status.code + ": " + status.text}
      >
        {`Customer was sucessfully created! (${status.body})`}
        <br />
        {status.code === 201 ? (
          <p>
            <Button to={"/editcustomer/" + status.body.slice(1, -1)}>
              Edit customer
            </Button>
          </p>
        ) : null}
      </Modal>

      <h2>Create a customer</h2>

      <p>Please enter the Companys Name:</p>

      <input
        className="border-2 p-1 w-full"
        value={newDisplayName}
        onChange={(e) => setNewDisplayName(e.target.value)}
      />

      {newDisplayName.length > 3 &&
        customer
          .filter((c) =>
            c.displayName.toLowerCase().includes(newDisplayName.toLowerCase())
          )
          .map((c) => (
            <p className="flex items-center gap-2 text-red-600">
              <LuAlertCircle />
              {c.displayName} existiert bereits!
            </p>
          ))}

      <div>
        <Button onClick={createCustomer}>create customer</Button>
      </div>
    </>
  );
}
