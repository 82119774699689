import Button from "../Components/Button";

export default function Stats() {
  return (
    <div>
      <h1>Statistics</h1>
      <p>Select type of stats you want to see.</p>

      <div className="space-y-4 pt-4">
        <Button to="action">Action</Button>
      </div>
    </div>
  );
}
