import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../Components/Button";
import Legend from "../Components/Legend";
import LoadingAnimation from "../Components/LoadingAnimation";
import { Config } from "../Config";

function WorldFileWarning({
  customerName,
  worldName,
  isProduction,
  customerData,
}) {
  const [warning, setWarning] = useState();
  const url = (env) =>
    `https://careercaptainworlds.blob.core.windows.net/${env}worlds/${customerName}/${worldName}/${customerName}-${worldName}.data.br?time=${Date.now()}`;

  useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function load() {
    const productionResponse = await fetch(url("production"), {
      method: "HEAD",
    });

    if (productionResponse.status === 404 && isProduction) {
      setWarning("not-found-production");
      return;
    }

    if (
      customerData.filter(
        (el) => el.isProduction && el.worldName.includes("Overworld")
      ).length >= 1 &&
      productionResponse.status === 404
    ) {
      setWarning("not-found-overworld-is-production");
      return;
    }

    const stagingResponse = await fetch(url("staging"), {
      method: "HEAD",
    });

    if (
      productionResponse.headers.get("Content-Md5") ===
      stagingResponse.headers.get("Content-Md5")
    ) {
      setWarning("equal");
    } else {
      setWarning("not-equal");
    }
  }

  const color = () => {
    switch (warning) {
      case "not-found-overworld-is-production":
        return "crimson";
      case "not-equal":
        return "yellow";
      case "equal":
        return "green";
      default:
        return "gray";
    }
  };

  const text = () => {
    switch (warning) {
      case "not-found-overworld-is-production":
        return "Error: No file in production but Overworld is in production";
      case "not-found":
        return "Error: File not found but production flag is set";
      case "not-equal":
        return "Warning: Different files in staging and production";
      case "equal":
        return "Good: Same files in staging and production";
      default:
        return "State unknown";
    }
  };

  return (
    <div
      className="relative group size-6 rounded-full border-2 border-black "
      style={{
        backgroundColor: color(),
      }}
    >
      <div className="absolute hidden group-hover:block p-1 px-2 border-2 bottom-full bg-white w-max">
        {text()}
      </div>
    </div>
  );
}

export default function ListWorlds() {
  const [loading, setLoading] = useState(false);
  const [worlds, setWorlds] = useState([]);
  const [shown, setShown] = useState([]);

  const { filter, filterId } = useParams();

  useEffect(() => {
    getWorlds();
  }, [filter, filterId]); // eslint-disable-line react-hooks/exhaustive-deps

  async function getWorlds() {
    setLoading(true);

    const response = await fetch(Config.api.world.list);
    let data = await response.json();

    if (filter) {
      data = data.filter((item) => item[filter] === filterId);
    }

    const groupBy = (array, key) => {
      return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        return result;
      }, {});
    };

    const grouped = groupBy(data, "customerName");
    setWorlds(grouped);
    setLoading(false);
  }

  function handleExpand(i) {
    let edited = shown;
    edited[i] = !edited[i] || false;
    setShown(edited);
  }

  return (
    <>
      <h1>Worlds</h1>
      <p>All worlds are listed here.</p>

      <h2>
        {filter ? (
          <>
            [{filter}: {filterId}]
          </>
        ) : null}
      </h2>

      <LoadingAnimation loading={loading} />

      <table className="table-auto w-full border-2 border-slate-300 text-left">
        <thead className="border-2 border-slate-300">
          <tr>
            <th className="p-2">Folder</th>
            <th className="p-2">Exists since</th>
            <th className="p-2">Last time updated</th>
            <th className="p-2">File state</th>
            <th className="p-2">Jobs</th>
            <th className="p-2">Staging</th>
            <th className="p-2">Production</th>
            <th className="p-2">Preview</th>
            <th className="p-2">Images</th>
            <th className="p-2">To production</th>
            <th className="p-2">World</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(worlds).map((customer, i) => (
            <Fragment key={customer}>
              <tr>
                <td className="p-2">
                  <Button onClick={() => handleExpand(i)}>{customer}</Button>
                </td>
              </tr>

              {shown[i]
                ? worlds[customer].map((el) => (
                    <tr
                      key={el.worldId}
                      className={
                        el.isProduction
                          ? "bg-red-100"
                          : el.isStaging
                          ? "bg-yellow-100"
                          : null
                      }
                    >
                      <td className="p-2">{el.worldName}</td>
                      <td className="p-2">
                        {new Date(el.startDate).toLocaleDateString("de-de", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        })}
                      </td>
                      <td className="p-2">
                        {new Date(el.lastUpdate).toLocaleDateString("de-de", {
                          weekday: "short",
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        })}
                      </td>
                      <td className="p-2">
                        <WorldFileWarning
                          customerData={worlds[customer]}
                          customerName={el.customerName}
                          worldName={el.worldName}
                          isProduction={el.isProduction}
                        />
                      </td>
                      <td className="p-2">
                        <Button to={`../job/worldId=${el.worldId}`}>
                          jobs
                        </Button>
                      </td>
                      <td className="p-2">{el.isStaging ? "yes" : null}</td>
                      <td className="p-2">{el.isProduction ? "yes" : null}</td>
                      <td className="p-2">{el.isPreview ? "yes" : null}</td>
                      <td className="p-2">
                        <Button to={`../imagesworld/${el.worldId}`}>
                          Change
                        </Button>
                      </td>
                      <td className="p-2">
                        <Button to={`../moveworld/${el.worldId}`}>Move</Button>
                      </td>
                      <td className="p-2">
                        <Button to={`../editworld/${el.worldId}`}>Edit</Button>
                      </td>
                    </tr>
                  ))
                : null}
            </Fragment>
          ))}
        </tbody>
      </table>

      <h2>Legend</h2>
      <Legend>World is neither in staging state nor in production state</Legend>
      <Legend color="bg-yellow-100">World is in staging state</Legend>
      <Legend color="bg-red-100">World is in production state</Legend>
    </>
  );
}
