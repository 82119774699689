import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../Components/Button";
import LoadingAnimation from "../Components/LoadingAnimation";
import { Config } from "../Config";
import { UserContext } from "../Layout/Main";

export default function ListPersons() {
  const [loading, setLoading] = useState(false);
  const [persons, setPersons] = useState([]);

  const user = useContext(UserContext);

  const { filter, filterId } = useParams();

  useEffect(() => {
    getPersons();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getPersons() {
    setLoading(true);

    const response = await fetch(Config.api.person.list);
    let data = await response.json();

    if (filter) {
      data = data.filter((item) => item[filter] === filterId);
    }

    data.sort((a, b) =>
      a?.customerDisplayName?.localeCompare(b?.customerDisplayName)
    );

    setPersons(data);
    setLoading(false);
  }

  return (
    <>
      <h1>Persons</h1>
      <p>All persons are listed here.</p>
      <h2>
        {filter ? (
          <>
            [{filter}: {filterId}]
          </>
        ) : null}
      </h2>

      <LoadingAnimation loading={loading} />

      <table className="table-auto w-full border-2 border-slate-300 text-left">
        <thead className="border-2 border-slate-300">
          <tr>
            <th className="p-2">Customer</th>
            <th className="p-2">First Name</th>
            <th className="p-2">Family Name</th>
            <th className="p-2">Edit</th>
          </tr>
        </thead>
        <tbody>
          {persons.map((person) => (
            <tr key={person.personId}>
              <td className="p-2">{person.customerDisplayName}</td>
              <td className="p-2">{person.firstName}</td>
              <td className="p-2">{person.familyName}</td>

              <td className="p-2">
                <Button to={`../editperson/${person.personId}`}>edit</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {user.permissionLevel >= 1 && filter === "customerId" && filterId && (
        <>
          <h2>Create</h2>
          <p>Create a new Person here: </p>

          <p>
            <Button to={`/createperson/${filterId}`}>Create person</Button>
          </p>
        </>
      )}
    </>
  );
}
