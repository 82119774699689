import { useEffect, useState } from "react";
import LoadingAnimation from "../Components/LoadingAnimation";
import Modal from "../Components/Modal";
import { Config, jwtExpiredStatus } from "../Config";
import Button from "../Components/Button";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import checkJwtExpiration from "../Functions/checkJwtExpiration";

const emptyStatus = { code: 0, message: "", body: "" };

export default function StatsAction(props) {
  const [loading, setLoading] = useState(true);

  const [status, setStatus] = useState(emptyStatus);

  const [customer, setCustomer] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [resource, setResource] = useState([]);

  const [showCCPage, setShowCCPage] = useState(true);
  const [showFrame, setShowFrame] = useState(true);
  const [ignoreResourceFilter, setIgnoreResourceFilter] = useState(false);
  const [stats, setStats] = useState();
  const [daysNumber, setDaysNumber] = useState(30);
  const [sum, setSum] = useState();
  const [filteredStats, setFilteredStats] = useState();

  function requestStats() {
    setLoading(true);
    let list = [];
    let inputs = document.querySelectorAll("input[name=resource]:checked");
    inputs.forEach((element) => {
      list.push(element.id);
    });

    const actionTypes = [];

    if (showCCPage) {
      actionTypes.push("customer-gamerender");
      actionTypes.push("job-gamerender");
    }
    if (showFrame) {
      actionTypes.push("customer-frame");
      actionTypes.push("job-frame");
    }

    const body = [
      !ignoreResourceFilter
        ? {
            key: "RecourceId",
            value: list,
          }
        : {},
      {
        key: "Action",
        value: actionTypes,
      },
    ];

    if (!checkJwtExpiration(props.token)) {
      setStatus(jwtExpiredStatus);
      setLoading(false);
      return;
    }

    fetch(Config.api.tracking.getActions, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${props.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          setStats(data);
          setLoading(false);
          setStatus(emptyStatus);
        });
      } else {
        response.text().then((text) => {
          setLoading(false);
          setStatus({
            code: response.status,
            text: response.statusText,
            body: text,
          });
        });
      }
    });
  }

  useEffect(() => {
    fetch(Config.api.customer.list).then((response) => {
      response.json().then((data) => {
        setCustomer(data);
        setLoading(false);
      });
    });
    fetch(Config.api.job.list).then((response) => {
      response.json().then((data) => {
        setJobs(data);
        setLoading(false);
      });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    var list = [];
    customer
      .filter((el) => !el.expiryDate)
      .sort((a, b) => {
        return a.displayName.localeCompare(b.displayName);
      })
      .forEach((customer) => {
        list.push({
          name: `Overworld ${customer.displayName}`,
          type: "customer",
          id: customer.customerId,
        });
        jobs
          .filter((el) => el.customerId === customer.customerId)
          .sort((a, b) => {
            return a.displayName.localeCompare(b.displayName);
          })
          .forEach((job) => {
            list.push({
              name: `${job.customerDisplayName} - ${job.displayName}`,
              type: "job",
              id: job.jobId,
            });
          });
      });

    setResource(list);
  }, [jobs, customer]);

  useEffect(() => {
    if (!stats) return;

    const relativeDays = Array.from(
      { length: daysNumber },
      (_, k) => k - daysNumber + 1
    );
    const days = relativeDays.map((i) =>
      new Date(Date.now() + i * 24 * 60 * 60 * 1000)
        .toISOString()
        .substring(0, 10)
    );

    const filtered = days.map((day) => ({
      date: day,
      count: stats.filter((e) => e.Timestamp.startsWith(day)).length,
    }));

    setFilteredStats(filtered);
    setSum(filtered.reduce((a, b) => a + b.count, 0));
  }, [stats, daysNumber]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <h1>Statistics of Views</h1>
      <p>Select type of stats you want to see.</p>

      {
        <Modal
          open={status.code !== 0}
          title={status.code + ": " + status.text}
        >
          {status.body}
        </Modal>
      }

      <div className="flex flex-row-reverse gap-8">
        <div className="basis-1/2 space-y-4">
          <Button onClick={requestStats}>load data with selected data</Button>

          <h2>Stats</h2>

          <LoadingAnimation loading={loading} />

          <div className="flex gap-2 items-baseline">
            <p>Summe an Aufrufen: </p>
            <p className="font-bold text-xl">{sum}</p>
          </div>

          <p>
            Zeige die letzten{" "}
            <input
              className="p-1 w-20 border-2"
              type="number"
              value={daysNumber}
              onChange={(e) => setDaysNumber(e.target.value)}
            />{" "}
            Tage
          </p>

          <div className="w-full h-[500px]">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={filteredStats}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="count" className="fill-green-400" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="basis-1/2 space-y-4">
          <h2>Type of views</h2>

          <div className="flex flex-col gap-2">
            <label>
              <input
                checked={showCCPage}
                onChange={(e) => setShowCCPage(e.target.checked)}
                type="checkbox"
              />{" "}
              show career-captain.de views
            </label>
            <label>
              <input
                checked={showFrame}
                onChange={(e) => setShowFrame(e.target.checked)}
                type="checkbox"
              />{" "}
              show frame views (customer websites)
            </label>
          </div>

          <h2>List of resources</h2>

          <label>
            <input
              checked={ignoreResourceFilter}
              onChange={(e) => setIgnoreResourceFilter(e.target.checked)}
              type="checkbox"
            />{" "}
            ignore resource filter (this will show all resources and also
            already deleted resources)
          </label>

          <div className="flex gap-2">
            <Button
              onClick={() => {
                document
                  .querySelectorAll("input[name=resource]")
                  .forEach((element) => {
                    element.checked = true;
                  });
              }}
            >
              check all
            </Button>

            <Button
              onClick={() => {
                document
                  .querySelectorAll("input[name=resource]")
                  .forEach((element) => {
                    element.checked = false;
                  });
              }}
            >
              uncheck all
            </Button>
          </div>

          <table className="table-auto w-full border-2 border-slate-300 text-left">
            <thead className="border-2 border-slate-300">
              <tr>
                <th className="p-2">Check</th>
                <th className="p-2">Display name</th>
              </tr>
            </thead>
            <tbody>
              {resource.map((r) => (
                <tr
                  key={r.id}
                  className={r.type === "customer" ? "bg-green-200" : "bg-none"}
                >
                  <td className="p-2">
                    <input
                      defaultChecked
                      type="checkbox"
                      name="resource"
                      id={r.id}
                    />
                  </td>
                  <td className="p-2">{r.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
