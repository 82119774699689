import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Config, jwtExpiredStatus } from "../Config";
import Button from "../Components/Button";
import Modal from "../Components/Modal";
import LoadingAnimation from "../Components/LoadingAnimation";
import checkJwtExpiration from "../Functions/checkJwtExpiration";

export default function MoveWorld(props) {
  const { worldId } = useParams();

  const [world, setWorld] = useState([]);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState({ code: 0, message: "", body: "" });

  useEffect(() => {
    getWorld();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getWorld() {
    setLoading(true);

    const response = await fetch(Config.api.world.get.replace("{id}", worldId));
    const data = await response.json();

    setWorld(data);
    setLoading(false);
  }

  async function handleWorldMove() {
    if (!checkJwtExpiration(props.token)) {
      setStatus(jwtExpiredStatus);
      return;
    }

    setLoading(true);

    const response = await fetch(
      Config.api.world.move.replace("{id}", worldId),
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${props.token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    setStatus({
      code: response.status,
      text: response.statusText,
      body: await response.text(),
    });
    setLoading(false);
  }

  return (
    <>
      <h1>Move world to Production</h1>
      <p>
        This operation overwrites the current production world with the current
        staging world.
      </p>
      <p>Make sure everything is working in the current staging world.</p>

      <LoadingAnimation loading={loading} />
      {
        <Modal
          open={status.code !== 0 && !loading}
          title={status.code + ": " + status.text}
        >
          {status.body}
        </Modal>
      }

      <h2>Information</h2>

      <p>World folder:</p>

      <div className="flex flex-row gap-2">
        <p className="bg-cclightgreen w-fit p-1 px-2 border-2 border-ccdarkgreen">
          stagingworlds/{world.customerName}/{world.worldName}
        </p>
        <p className="p-2">➔</p>
        <p className="bg-cclightgreen w-fit p-1 px-2 border-2 border-ccdarkgreen">
          productionworlds/{world.customerName}/{world.worldName}
        </p>
      </div>

      <h2 className="text-2xl font-normal">Move world</h2>

      <p>Warning: This will also set the arcweave date to tomorrow!</p>

      <div>
        <Button onClick={handleWorldMove}>Move world to production</Button>
      </div>
    </>
  );
}
