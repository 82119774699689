import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../Components/Button";
import LoadingAnimation from "../Components/LoadingAnimation";
import Modal from "../Components/Modal";
import { Config, jwtExpiredStatus } from "../Config";
import { UserContext } from "../Layout/Main";
import checkJwtExpiration from "../Functions/checkJwtExpiration";

export default function EditPreviewModule(props) {
  const user = useContext(UserContext);

  const { previewmoduleId } = useParams();

  // const refArray = useRef([]);

  const [showDelete, setShowDelete] = useState(false);

  // const [module, setModule] = useState([]);

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ code: 0, message: "", body: "" });

  // useEffect(() => {
  //   getData();
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // async function getData() {
  //   setLoading(true);

  //   const response = await fetch(
  //     Config.api.previewmodule.get.replace("{id}", previewmoduleId)
  //   );
  //   const data = await response.json();
  //   setModule(data);

  //   setLoading(false);
  // }

  // async function changeModule() {
  //   setLoading(true);

  //   const bodyjson = JSON.stringify({
  //     firstName: refArray.current.firstName.value,
  //     familyName: refArray.current.familyName.value,
  //     email: refArray.current.email.value,
  //     phoneNumber: refArray.current.phoneNumber.value,
  //   });

  //   const response = await fetch(
  //     Config.api.previewmodule.changevalues.replace("{id}", previewmoduleId),
  //     {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${props.token}`,
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: bodyjson,
  //     }
  //   );

  //   setLoading(false);
  //   let responsetext = await response.text();

  //   setStatus({
  //     code: response.status,
  //     text: response.statusText,
  //     body: responsetext,
  //   });
  // }

  async function deleteModule() {
    setLoading(true);

    if (!checkJwtExpiration(props.token)) {
      setStatus(jwtExpiredStatus);
      setLoading(false);
      return;
    }

    const response = await fetch(
      Config.api.previewmodule.delete.replace("{id}", previewmoduleId),
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${props.token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    setLoading(false);
    let responsetext = await response.text();

    setStatus({
      code: response.status,
      text: response.statusText,
      body: responsetext,
    });
  }

  return (
    <>
      <h1>Edit Module</h1>
      <p>Here are infos of a module.</p>
      <LoadingAnimation loading={loading} />
      {
        <Modal
          open={status.code !== 0 && !loading}
          title={status.code + ": " + status.text}
        >
          {status.body}
        </Modal>
      }
      <h2>Module [{previewmoduleId}]</h2>

      {/* <div>
        <Button onClick={() => changeModule()}>Change Values</Button>
      </div> */}

      {user.permissionLevel >= 2 && (
        <>
          <h2>Delete module</h2>
          <input
            onChange={(e) => {
              e.target.checked ? setShowDelete(true) : setShowDelete(false);
            }}
            type="checkbox"
            defaultChecked={false}
          />{" "}
          I'm sure I want to delete this module
          <div className={showDelete ? "visible" : "invisible"}>
            <Button onClick={() => deleteModule()}>Delete module</Button>
          </div>
        </>
      )}
    </>
  );
}
